import {useEffect, useState} from "react";
import {MsalAuthenticationTemplate, useAccount, useMsal} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {loginRequest, protectedResources} from "../../components/common/authConfig";
import {CloudEnvironmentData} from "../../components/Reporting/CloudEnvironmentItem";
import PaginatedQuery from "../../utils/PaginatedQuery";

function CloudEnvironmentsPage() {
    return (
        <div>
            <CloudEnvironmentItems />
        </div>
    );
}

export default CloudEnvironmentsPage;

export const CloudEnvironmentItems = (props) => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest} >
            <CloudEnvironmentItem props={props} />
        </MsalAuthenticationTemplate>
    )
};

const CloudEnvironmentItem = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [results, setResults] = useState(null);

    const queryName = "listCloudEnvironments";
    const queryString = `
        query ${queryName}($nextToken: String) {
            ${queryName}(nextToken: $nextToken) {
                items {
                    cloud_environment_id
                    friendly_name
                    status
                    environment_type
                    it_service_owner_name
                    it_service_owner_email
                    delegates_group_name
                    delegates_members {
                        email
                        name
                    }
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiReporting.endpoint,
        protectedResources.apiReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none") {
                query.getFullResult(
                    account,
                    instance,
                    {}
                ).then(data => setResults(data))
            }
        },
        [account, inProgress, instance]
    );

    console.log(results)
    return (
        <>
            {results ? <CloudEnvironmentData cloudEnvironmentData={results} /> : null}
        </>
    );
};