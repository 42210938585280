// Table.js

import React, {useCallback, useEffect} from "react";
import {
    useAsyncDebounce,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from "react-table";
import styled from 'styled-components';
import "./Table.css"
import {matchSorter} from 'match-sorter';
import * as ExcelJS from 'exceljs';
import {saveAs} from 'file-saver'
import useContactGroups from "./ContactsGroups"

const Styles = styled.div`
  padding: 1rem;
  padding-left: 0;

  .pagination {
    text-align:center;
    display:block;
  }

  table,th,td{
    border: none !important;
  }

  tr:hover{
    background: #fff;
    :nth-child(odd){
      background-color: #E6E6E6
    }
  }

  .table {
      display: ridge;
      background: #fff;
      border-collapse: collapse;

      .tr {

          color: #000;

          :nth-child(odd) {
              background-color: #E6E6E6;
          }
      }

      .tableHeadBorder {
          border-bottom: 5px #A1BF89 solid;
      }

      .th:nth-child(even) > span.sortArrow {
          background-color: #B8DA9B;
      }

      .th {
          background: #B8DA9B;
          color: #475738;
          line-height: 60px;
          padding: 0px 15px;
      }

  ,
  . td, td {
      margin: 0;
      padding: 15px;
      height: auto !important;
  }

      .tr > td > div.showJsonBtn > button.showJson.showJson.showJson {
          background-color: #B9B9B9;
      }

      .tr > td:nth-child(even) > div.showJsonBtn.showJsonBtn {
          background-color: #FFF;
      }
  }
`

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="global-filter"
      />
    </span>
  )
}


export default function Table({ columns, data, csvheaders, contactsGroupConfiguration = {enable: false} }) {

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    //@ts-ignore
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100,
      width: 250,
      maxWidth: 600,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useBlockLayout,
    useResizeColumns,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );

  // Separate table instance WITHOUT pagination. This is used ONLY for export
  const exportTable = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: {
            filters: state.filters,
            globalFilter: state.globalFilter
        },
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
  );

  useEffect(() => {
    // Update exportTable state to match the state of main table instance.
    exportTable.setAllFilters(state.filters)
    exportTable.setGlobalFilter(state.globalFilter)
  }, [state.filters, state.globalFilter])

  const [
      ContactGroupsModalDiv,
      showGroupsCallback
  ] = useContactGroups(exportTable, contactsGroupConfiguration)

  const downloadExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Public IPs');

    // Define the headers
    worksheet.columns = csvheaders.map(header => ({ header: header.label, key: header.key, width: 10 }));

    // Get filtered data. Replace 'page' with 'rows' if not using usePagination
    const filteredData = exportTable.rows.map(row => row.original);

    // Add rows (data)
    filteredData.forEach((row) => {
      worksheet.addRow(row);
    });

    // Write the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Use 'file-saver' to save the file
    saveAs(new Blob([buffer]), 'ExcelFile.xlsx');
  }, [exportTable, csvheaders]);

  return (
    <>
      <Styles>
          {contactsGroupConfiguration.enable && <ContactGroupsModalDiv />}
          <div className="tableTopNav">

              <select
                  value={state.pageSize}
                  onChange={e => {
                      setPageSize(Number(e.target.value))
                  }}
                  className="form-select d-inline showPerPage"
              >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                      </option>
                  ))}
              </select>

              <button onClick={downloadExcel} className="formButton">Download as Excel</button>
              {contactsGroupConfiguration.enable && <button onClick={showGroupsCallback} className="formButton">Get contacts</button>}

          </div>

          <div className="wrap">
            <table {...getTableProps()} className="table">
            <thead>
          {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr tableHeadBorder">
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                    <span {...column.getSortByToggleProps()} className="sortArrow">
                      {column.isSorted ? (column.isSortedDesc ? " \u2B07" : " \u2B06") : " \u21C5"}
                    </span>
                    {/* Render the columns filter UI */}
                    <div className="tableFilter">{column.canFilter ? column.render('Filter') : null}</div>
                    
                    {/* Resizer */}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                    />
                  </th>
                ))}
              </tr>
            ))}
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                    backgroundColor: '#343434'
                    
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
                </tr>
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className="tr">
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')} </td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="pageButtons"><span>&laquo;</span></button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className="pageButtons me-4"><span>&lsaquo;</span></button>{' '}
         
          <span>
            Page{' '}
            <strong>
              {state.pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={state.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px', display: 'inline-block' }}
              className="form-control"
            />
          </span>
     
          <button onClick={() => nextPage()} disabled={!canNextPage} className="pageButtons ms-4"><span>&rsaquo;</span></button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="pageButtons"><span>&raquo;</span></button>{' '}
         
          
        </div>
        
      </Styles>
    </>

  )
}