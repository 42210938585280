import {useRef, useState} from "react";
import Modal from "./Modal";

const shouldShowButton = (value) => {
    if (value === null) return false;
    if (value === "{}") return false;
    if (value === "[]") return false;
    return true;
}

const useJsonCellRenderer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const content = useRef(null);

    const JsonCellRenderer = ({ value }) => {
        console.log(value);
        return (
            <div style={{ fontSize: '14px' }} className="showJsonBtn">
                {shouldShowButton(value) ? <button onClick={() => ShowJson({value})} className="btn btn-sm btn-secondary showJson">Show</button> : null}
            </div>
        );
    };

    const ShowJson = ({ value }) => {

        let res = value;
        if (typeof value === "string")
            res = JSON.parse(value)
    
        let html = "<table>";
        for (const key in res){
            if (res.hasOwnProperty(key)) {
                let val = "";
                if(typeof res[key] === 'object'){
                    val = JSON2HTMLList(res[key]).innerHTML;
                }else{
                    val = res[key];
                }
                html += "<tr><td>"+key+"</td><td>"+val+"</td></tr>";
            }
        }
    
        html += "</table>";
        content.current = html;
        setIsOpen(true);
    };

    const ModalDiv = () => (
        <div id="modal-popup">
            {isOpen && <Modal setIsOpen={setIsOpen} content={content.current} />}
        </div>
    )

    return [ModalDiv, JsonCellRenderer];
}

export default useJsonCellRenderer;

var _options = {
    container: 'list',
    formatContainer: formatContainer,
    formatUl: formatUl,
    formatLi: formatLi,
    formatProperty: formatProperty,
    formatValue: formatValue
};

function formatContainer(container) {
    container.className = 'container';
    return container;
}

function formatUl(ul) {
    ul.className = 'ul-item';
    return ul;
}


function formatLi(li) {
    li.className = 'li-item';
    return li; 
}


function formatProperty(prop) {
    var strong = document.createElement('strong');
    strong.appendChild(prop);
    return strong;
}


function formatValue(val, prop) {
    var span = document.createElement('span');
    span.appendChild(val);
    return span;
}

function JSON2HTMLList(json, options) {
    for(var opt in options) {
        if(options.hasOwnProperty(opt)) {
            _options[opt] = options[opt];
        }
    }

    var container = document.createElement(_options.container);
    container = _options.formatContainer(container);

    function walk(obj, parentElm) {
        if(typeof(obj) === 'object' && obj !== null && obj.constructor === Object ) {
            var ul = document.createElement('ul');
            ul = _options.formatUl(ul);
            var hasCount = 0;
            for(var prop in obj) {
                if(obj.hasOwnProperty(prop)) {
                    var li = document.createElement('li');
                    li = _options.formatLi(li);
                    ul.appendChild(li); 

                    if(typeof(obj[prop]) !== 'object' || obj[prop] === null) {

                        var propText = document.createTextNode(prop);
                        propText = _options.formatProperty(propText);
                        
                        li.appendChild(propText);

                        var valueText  = document.createTextNode(": "+obj[prop]);
                        valueText = _options.formatValue(valueText, prop);

                        li.appendChild(valueText);

                        hasCount++;
                    } else {
                        var propText = document.createTextNode(prop);
                        propText = _options.formatProperty(propText);

                        li.appendChild(propText);

                        walk(obj[prop], li);
                    }
                }
            }
            parentElm.appendChild(ul);

        } else if(typeof(obj) === 'object' && obj !== null && obj.constructor === Array) {
            var ul = document.createElement('ul');
            ul = _options.formatUl(ul);

            var hasCount = 0;
            for(var i=0; i < obj.length; i++) {
                
                if(typeof(obj[i]) !== 'object' || obj[i] === null) {
                    var li = document.createElement('li');
                    li = _options.formatLi(li);

                    ul.appendChild(li);

                    var valueText = document.createTextNode(obj[i]);
                    valueText = _options.formatValue(valueText, i);

                    li.appendChild(valueText);

                    hasCount++;
                } else {
                    walk(obj[i], parentElm);
                }
            }
            parentElm.appendChild(ul);
        }
    }
    walk(json, container);

    return container;

}