import { Outlet } from 'react-router-dom';
import SideBarDatajson from "../../utils/data/ReportingSideBarData.json";

import SideBar from '../../components/component-pages/SideBar';

import "./Reporting.css"

function Reporting() {
    return (
        <div className="row-portal">
            <div className="column1-portal">
                <SideBar data={SideBarDatajson} rootPath="/Reporting" />
            </div>
            <div className="column2-portal">
                <Outlet />
            </div>
        </div>
    );   
}

export default Reporting;