import { AppConfig } from '../../../../config/app.config'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { ApolloLink } from '@apollo/client/link/core'
import { AppSyncConfig } from '../../../../config/appsync.config'
import { GQLHTTPEndpoint } from '../../../../constants/app.constants'

enum AUTH_TYPE {
    NONE = "NONE",
    API_KEY = "API_KEY",
    AWS_IAM = "AWS_IAM",
    AMAZON_COGNITO_USER_POOLS = "AMAZON_COGNITO_USER_POOLS",
    OPENID_CONNECT = "OPENID_CONNECT",
    AWS_LAMBDA = "AWS_LAMBDA"
}

const url = GQLHTTPEndpoint

const region = AppSyncConfig.REGION as string

const auth = {
    type: AppSyncConfig.TYPE as AUTH_TYPE,
    apiKey: AppSyncConfig.API_KEY as string,
    // jwtToken: async () => token, // when you use Cognito UserPools OR OpenID Connect
    // credentials: async () => credentials, // when using an IAM-based auth.
}

export const attachAppsSyncAuthLinks = (httpLink: ApolloLink) => {
    const authOpts = { url, region, auth: { apiKey: auth.apiKey, type: auth.type as any } }
    return ApolloLink.from([
        createAuthLink(authOpts) as unknown as ApolloLink,
        createSubscriptionHandshakeLink(
            {
                auth: authOpts,
                region: region,
                url: url,
            } as any,
            httpLink as any
        ) as unknown as ApolloLink,
    ])
}
