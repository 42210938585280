import { ApolloProvider } from '@apollo/client'
import { useMsal } from '@azure/msal-react'
import { ApolloFactory } from '../graphql/apollo/client/client'
import { useAccount } from '@azure/msal-react';
import { useEffect, useState } from 'react';

export const ApolloMsalProvier = ({ children }) => {
  const [client, setClient] = useState(null);

  const { instance, inProgress, accounts } = useMsal()

  useEffect(() => {
    const getClient = async () => {
      setClient(await ApolloFactory.createMSALAuthClient(instance, inProgress, accounts));
    }

    getClient();
  }, [instance, inProgress, accounts]);


  return client ? <ApolloProvider client={client}>{children}</ApolloProvider> : <>{children}</>;
}