import HomeMenu from "../components/component-pages/HomeMenu";
import reportingData from "../utils/data/ReportingSideBarData.json";
import certData from "../utils/data/CertSideBarData.json";
import supportData from "../utils/data/SupportSideBarData.json"
import "../components/common/Home.css"

const Home = () => {
	return (
        <>
            <div className="homeBanner">
                <div class="container">
                    <h1><span>Welcome to</span><br />AWS Security Engineering</h1>
                </div>
            </div>
            <div className="greyBg h-100">
                <div className="container">
                    <div class="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col">
                            <div class="card h-100">
                                <div class="row g-0 h-100">
                                    <div class="col-md-4 h-100">
                                        <div class="h-100 homeLink reportingLink">
                                            <span>Reporting</span>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body homeLinks">
                                            {reportingData.SideBarItems.map((item, index) => {
                                                return item.display ? (
                                                    <HomeMenu item={item} key={index} rootPath="/Reporting" />
                                                ) : null
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100">
                                <div class="row g-0 h-100">
                                    <div class="col-md-4 h-100">
                                        <div class="h-100 homeLink certLink">
                                            <span>CERT</span>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body homeLinks">
                                            {certData.SideBarItems.map((item, index) => {
                                                return item.display ? (
                                                    <HomeMenu item={item} key={index} rootPath="/cert" />
                                                ) : null
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col">
                            <div class="card h-100">
                                <div class="row g-0 h-100">
                                    <div class="col-md-4 h-100">
                                        <div class="h-100 homeLink helpLink">
                                            <span>Help & Support</span>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body homeLinks">
                                            {supportData.SideBarItems.map((item, index) => {
                                                return item.display ? (
                                                    <HomeMenu item={item} key={index} rootPath="/support" />
                                                ) : null
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Home;