import React from "react";
import Table from "../common/Tables";

export const CtrAccessPointData = (props) => {

    const columns = React.useMemo(
        () => [
                {
                    Header: 'Account Id',
                    accessor: 'AccountId',
                    sortType: 'basic',
                },
                {
                    Header: 'CloudTrail AccessPoint Name',
                    accessor: 'CtrAccessPoint',
                    sortType: 'basic',
                },
                {
                    Header: 'CloudTrail AccessPoint Alias',
                    accessor: 'CtrAccessPointAlias',
                    sortType: 'basic',
                },
            ],
        []);

        const csvheaders = React.useMemo(
            () => [
                    {
                        label: 'Account Id',
                        key: 'AccountId',
                    },
                    {
                        label: 'CloudTrail AccessPoint Name',
                        key: 'CtrAccessPoint',
                    },
                    {
                        label: 'CloudTrail AccessPoint Alias',
                        key: 'CtrAccessPointAlias',
                    },
                ],
            []);
    
            console.log(props);
    return (
        <>
            <Table columns={columns} data={props.ctrAccessPointData ?? []} csvheaders={csvheaders} />
        </>
    );
}