import { useContext, useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest, protectedResources } from "../../components/common/authConfig";
import { ApplicationData } from "../../components/Reporting/ApplicationItem";
import { SessionStoreContext } from "../../utils/SessionStore";
import SearchListForm from "../../components/common/SearchListForm";
import PaginatedQuery from "../../utils/PaginatedQuery";

function FindApplication() {
    const sessionState = useContext(SessionStoreContext);
    const [searchFilter, setSearchFilter] = useState("");

    sessionState.useOnReady(() => {
        setSearchFilter(sessionState.getItem("find-application-search-filter", "NetworkManager-tui"));
    });

    const onFilterForApplication = (returnedSearchFilter) => {
        setSearchFilter(sessionState.setItem("find-application-search-filter", returnedSearchFilter));
    };

    return (
        <div>
            <SearchListForm onFilter={onFilterForApplication} searchCriteriaName="Application" type="application" />
            <ApplicationItems props={searchFilter} />
        </div>
    );
}

export default FindApplication;


const ApplicationContent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [results, setResults] = useState(null);
    const newQueryVar = props.props.props;

    const queryName = "listApplications";
    const queryString = `
        query ${queryName}($name: String!, $nextToken: String) {
            ${queryName}(name: $name, nextToken: $nextToken) {
                items {
                    name 
                    resourceid
                    applicationtype
                    installedtime
                    architecture
                    summary
                    accountid
                    packageid 
                    publisher
                    version
                    release
                    url
                    capturetime
                    epoch
                    region
                    instancestatus
                    agentversion
                    platformtype
                    platformversion
                    tagkey
                    tagvalue
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiReporting.endpoint,
        protectedResources.apiReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none" && newQueryVar) {
                query.getFullResult(
                    account,
                    instance,
                    {name: newQueryVar}
                ).then(data => setResults(data))
            }
        },
        [account, inProgress, instance, newQueryVar]
    );

    console.log(results)
    return (
        <>
            {results ? <ApplicationData applicationData={results} /> : null}
        </>
    );
};

export const ApplicationItems = (props) => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest} >
            <ApplicationContent props={props} />
        </MsalAuthenticationTemplate>
    )
};