export const callDynamoDB = async (idToken, url, investigationId, axios) => {
    const bearer = `${idToken}`;
    const apiEndpoint =`${url}?InvestigationId=${investigationId}`;

    const options = {
        headers: {
            "authorizationToken": bearer,
            "Content-Type": "application/json",
        },
        redirect: "follow"
    };
    return axios.get(apiEndpoint, options)
        .then(data => data.data);
}
