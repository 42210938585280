import Select from "react-select";

export const SearchListData = (props) => {
    const [context, setContext] = props.ctx;

    const nameChangeHandler = (event) => {
        setContext(event.value)
    }

    const options = props.searchData.map(items => ({
        "value": items.name,
        "label": items.name
    }));
    return (
        <Select options={options} onChange={nameChangeHandler} />
    );
}