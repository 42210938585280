import React , { useState } from 'react';
import  '../component-css/ForensicAnalysis.css';


const ForensicAnalysis = ()  => {
    const [instanceId, setInstanceId] = useState("");

    function checInstanceHandler() {
        
        
        alert(instanceId);   
    }

    return (
        <div className= "forensic-root">
            <h2 style = {{ textAlign: 'left', backgroundColor: 'white' }}>Central Intelligence Unit</h2>
            <div className ="forensic-form">
                <div className="forensic-label">
                    <label htmlFor="input-2">View Instance Details</label> 
                </div>
                <div className="form-input">
                    <input style = { {height: "30px", width: "400px"} } type="text"  value = {instanceId} onChange={(e) => setInstanceId(e.target.value)}/>
                </div>  
                <div className="form-button">
                     <input type="submit" onClick= {checInstanceHandler} value="Check"></input>
                </div>    
            </div>  
        </div>
    );
}
export default ForensicAnalysis;