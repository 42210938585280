import { useEffect, useState, useContext } from "react";
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest, protectedResources } from "../../components/common/authConfig";
import { P2TopIPsData } from "../../components/Reporting/P2TopIPsItem";
import { SessionStoreContext } from "../../utils/SessionStore";
import SearchListForm from "../../components/common/SearchListForm";
import PaginatedQuery from "../../utils/PaginatedQuery";

function FindP2TopIPs() {
    const sessionState = useContext(SessionStoreContext);
    const [searchFilter, setSearchFilter] = useState("");

    const onFilterForP2TopIPs = (returnedSearchFilter) => {
        sessionState.setItem("find-clientip-search-filter", returnedSearchFilter);
        setSearchFilter(returnedSearchFilter);
    };

    sessionState.useOnReady(() => {
        setSearchFilter(sessionState.getItem("find-P2TopIPs-search-filter", "52.51.67.10"));
    });

    return (
        <div>
            <SearchListForm onFilter={onFilterForP2TopIPs} searchCriteriaName="Client IP" type="P2TopIPs" />
            <P2TopIPsItems props={searchFilter} />
        </div>
    );
}

export default FindP2TopIPs;

const P2TopIPsContent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [results, setResults] = useState(null);
    const newQueryVar = props.props.props;

    const queryName = "listP2TopIPs";
    const queryString = `
        query ${queryName}($name: String!, $nextToken: String) {
            ${queryName}(clientip: $name, nextToken: $nextToken) {
                items {
                    webaclid
                    sourceip
                    action
                    terminatingruleid
                    min_datetime
                    max_datetime
                    count
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiReporting.endpoint,
        protectedResources.apiReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none" && newQueryVar) {
                query.getFullResult(
                    account,
                    instance,
                    {name: newQueryVar}
                ).then(data => setResults(data))
            }
        },
        [account, inProgress, instance, newQueryVar]
    );

    console.log(results);
    return (
        <>
            {results ? <P2TopIPsData p2TopIPsData={results} /> : null}
        </>
    );
};

export const P2TopIPsItems = (props) => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest} >
            <P2TopIPsContent props={props} />
        </MsalAuthenticationTemplate>
    )
};
