import React from "react";
import Table from "../common/Tables";

export const ServiceData = (props) => {

    const columns = React.useMemo(
        () => [
                {
                    Header: 'Service Name',
                    accessor: 'name',
                    filter: 'fuzzyText',
                    sortType: 'basic',
                },
                {
                    Header: 'InstanceId',
                    accessor: 'resourceid',
                    sortType: 'basic',
                },
                {
                    Header: 'Account Id',
                    accessor: 'accountid',
                    sortType: 'basic',
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    sortType: 'basic',
                },
                {
                    Header: 'Service Type',
                    accessor: 'servicetype',
                    sortType: 'basic',
                },
                {
                    Header: 'DependsOn',
                    accessor: 'servicesdependon',
                    sortType: 'basic',
                },
                {
                    Header: 'Display Name',
                    accessor: 'displayname',
                    sortType: 'basic',
                },
                {
                    Header: 'Dependent Services',
                    accessor: 'dependentservices',
                    sortType: 'basic',
                },
                {
                    Header: 'Start Type',
                    accessor: 'starttype',
                    sortType: 'basic',
                },
                {
                    Header: 'Capture Time',
                    accessor: 'capturetime',
                    sortType: 'basic',
                },
                {
                    Header: 'Region',
                    accessor: 'region',
                    sortType: 'basic',
                },
                {
                    Header: 'Resource Type',
                    accessor: 'resourcetype',
                    sortType: 'basic',
                },
                {
                    Header: 'Instance Status',
                    accessor: 'instancestatus',
                    sortType: 'basic',
                },
                {
                    Header: 'Agent Version',
                    accessor: 'agentversion',
                    sortType: 'basic',
                },
                {
                    Header: 'Platform Type',
                    accessor: 'platformtype',
                    sortType: 'basic',
                },
                {
                    Header: 'Platform Version',
                    accessor: 'platformversion',
                    sortType: 'basic',
                },
            ],
        []);

        const csvheaders = React.useMemo(
            () => [
                    {
                        label: 'Service Name',
                        key: 'name',
                    },
                    {
                        label: 'InstanceId',
                        key: 'resourceid',
                    },
                    {
                        label: 'Account Id',
                        key: 'accountid',
                    },
                    {
                        label: 'Status',
                        key: 'status',
                    },
                    {
                        label: 'Status',
                        key: 'status',
                    },
                    {
                        label: 'Service Type',
                        key: 'servicetype',
                    },
                    {
                        label: 'DependsOn',
                        key: 'servicesdependon',
                    },
                    {
                        label: 'Display Name',
                        key: 'displayname',
                    },
                    {
                        label: 'Dependent Services',
                        key: 'dependentservices',
                    },
                    {
                        label: 'Start Type',
                        key: 'starttype',
                    },
                    {
                        label: 'Capture Time',
                        key: 'capturetime',
                    },
                    {
                        label: 'Region',
                        key: 'region',
                    },
                    {
                        label: 'Resource Type',
                        key: 'resourcetype',
                    },
                    {
                        label: 'Instance Status',
                        key: 'instancestatus',
                    },
                    {
                        label: 'Agent Version',
                        key: 'agentversion',
                    },
                    {
                        label: 'Platform Type',
                        key: 'platformtype',
                    },
                    {
                        label: 'Platform Version',
                        key: 'platformversion',
                    },
                ],
            []);
    
            console.log(props);
    return (
        <>
            <Table columns={columns} data={props.serviceData ?? []} csvheaders={csvheaders} />
        </>
    );
}