import { createContext, useContext, useEffect, useState } from "react";

import { Toast } from "react-bootstrap";

import { VscInfo, VscWarning, VscError, VscDebug } from "react-icons/vsc"

import "../component-css/Popup.css"

const PopupIcon = ({ type }) => {
    return {
        "info": <VscInfo className="popup-icon icon-info" />,
        "warn": <VscWarning className="popup-icon icon-warn" />,
        "error": <VscError className="popup-icon icon-error" />,
        "debug": <VscDebug className="popup-icon icon-debug" />,
    }[type] ?? null;
}

export const Popup = ({ state }) => {
    const [timeElapsed, setTimeElapsed] = useState(0);

    const [timeoutId, setTimeoutId] = useState(null); 
    const [intervalId, setIntervalId] = useState(null);

    const initPopup = () => {
        let interval_id = 0;
        let timeout_id = 0;
        if (state.timeout > 0) {
            if (intervalId === null)
                interval_id = setInterval(() => {
                    //console.log("popup interval fires");
                    setTimeElapsed((old) => old + 50);
                }, 50); // add 50ms to elapsed every 50ms 
            
            if (timeoutId === null)
                timeout_id = setTimeout(() => state.close(), state.timeout);  // close the popup after timeout milliseconds   
        }

        setIntervalId(interval_id);
        setTimeoutId(timeout_id);
    }

    const cleanupPopup = () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setIntervalId(null);
        setTimeoutId(null);
    }

    const handleMouseOver = () => {
        cleanupPopup();    
        setTimeElapsed(0);
    }

    const handleMouseOut = () => {
        initPopup();
    }

    useEffect(() => {
        initPopup();

        return cleanupPopup;
    }, [/* Leave this empty or it doesn't work*/]);

    return (
        <Toast show className="popup-container">
            <div className="popup-hitbox" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div onClick={state.close} className="popup-close" />
            </div>
            <Toast.Header style={{borderBottom: "green solid 1px"}}>
                <div className="popup-header">
                    <PopupIcon type={state.level} />
                    <strong>{state.title}</strong>
                </div>
            </Toast.Header>
            <Toast.Body className="popup-body">{state.msg}</Toast.Body>
            <progress className="popup-timeout" max={`${state.timeout}`} value={`${timeElapsed}`}></progress>
        </Toast>
    );
}

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {

    const [popups, setPopups] = useState([]);
    const [popupId, setPopupId] = useState(0);

    const LEVELS = ["info", "warn", "error", "debug"];
    const DEFAULT_LEVEL = "info";

    const closePopup = (key) => {
        setPopups((old) => old.filter(v => v.key !== key));
    };

    return (
        <PopupContext.Provider
            value={{
                addPopup: (level="info", title="", msg="", timeout=10000) => {
                    setPopups((old) => [
                        ...old,
                        {
                            key: popupId, 
                            level: LEVELS.includes(level) ? level : DEFAULT_LEVEL, 
                            close: () => closePopup(popupId),
                            title,
                            msg,
                            timeout,
                        }
                    ]);
                    setPopupId(old => old + 1);
                },
                popups,
            }}
        >
            {children}
        </PopupContext.Provider>
    );
}

export const PopupList = () => {

    const popupContext = useContext(PopupContext);

    return (
        <div className="popup-list">
            {popupContext && popupContext.popups.map(v => <Popup state={v} key={v.key} />)}
        </div>
    )
}