import { AppConfig } from '../config/app.config'
import _styled from 'styled-components';

// export const APP_HOST_DOMAIN = process.env.REACT_APP_DEPLOYED_DOMAIN || window.location.origin
// export const isDEV = process.env.REACT_APP_LOCAL_TEST == 'true'
export const isDEV = true;
export const isHTTPS = true;

let reportingGraphUrl = new URL(AppConfig.reportingGraphqQLEndpoint)
export const ReportingGQLHTTPEndpoint = `${!isHTTPS ? 'http://' : 'https://'}${reportingGraphUrl.host}/graphql`;

let graphUrl = new URL(AppConfig.graphqQLEndpoint)
export const GQLHTTPEndpoint = `${!isHTTPS ? 'http://' : 'https://'}${graphUrl.host}/graphql`;

export const styled = _styled;