import React from 'react';
import Button from 'react-bootstrap/Button';

const Logout = ()  => {
    return (
        <Button variant="success">
           Logout
        </Button>
    );
}
export default Logout;