import React from "react";
import Table from "../common/Tables";
import useJsonCellRenderer from "../common/JsonCellRenderer";

export const PublicIpData = (props) => {
    const [ModalDiv, JsonCellRenderer] = useJsonCellRenderer();

    const columns = React.useMemo(
        () => [
                {
                    Header: 'PublicIP',
                    accessor: 'publicip',
                    sortType: 'basic',
                },
                {
                    Header: 'ResourceId',
                    accessor: 'resourceId',
                    filter: 'fuzzyText',
                    sortType: 'basic',
                },
                {
                    Header: 'Region',
                    accessor: 'awsRegion',
                    sortType: 'basic',
                },
                {
                    Header: 'AccountId',
                    accessor: 'awsAccountId',
                    sortType: 'basic',
                },
                {
                    Header: 'ReverseDNSLookup',
                    accessor: 'reverseDnsLookup',
                    sortType: 'basic',
                },
                {
                    Header: 'Configuration',
                    accessor: 'configuration',
                    Cell: JsonCellRenderer,
                },
                {
                    Header: 'AWS-AZ',
                    accessor: 'availabilityZone',
                    sortType: 'basic',
                },
                {
                    Header: 'Relationships',
                    accessor: 'relationships',
                    Cell: JsonCellRenderer,
                },
                {
                    Header: 'Interface Type',
                    accessor: 'InterfaceType',
                    sortType: 'basic',
                },
                {
                    Header: 'Item Capture Time',
                    accessor: 'configurationitemcapturetime',
                    sortType: 'basic',
                },
                {
                    Header: 'PrivateIp',
                    accessor: 'PrivateIp',
                    sortType: 'basic',
                },
                {
                    Header: 'Version',
                    accessor: 'configurationitemversion',
                    sortType: 'basic',
                },
                {
                    Header: 'Resource Type',
                    accessor: 'resourceType',
                    sortType: 'basic',
                },
            ], [JsonCellRenderer]
        );

        const csvheaders = React.useMemo(
            () => [
                {
                    label: 'PublicIP',
                    key: 'publicip',
                },
                {
                    label: 'ResourceId',
                    key: 'resourceId',
                },
                {
                    label: 'Region',
                    key: 'awsRegion',
                },
                {
                    label: 'AccountId',
                    key: 'awsAccountId',
                },
                {
                    label: 'ReverseDnsLookup',
                    key: 'reverseDnsLookup',
                },
                {
                    label: 'Configuration',
                    key: 'configuration',
                },
                {
                    label: 'AWS-AZ',
                    key: 'availabilityZone',
                },
                {
                    label: 'Relationships',
                    key: 'relationships',
                },
                {
                    label: 'Interface Type',
                    key: 'InterfaceType',
                },
                {
                    label: 'Item Capture Time',
                    key: 'configurationitemcapturetime',
                },
                {
                    label: 'PrivateIp',
                    key: 'PrivateIp',
                },
                {
                    label: 'Version',
                    key: 'configurationitemversion',
                },
                {
                    label: 'Resource Type',
                    key: 'resourceType',
                },
                ],
            []);
    
            console.log(props);
    return (
        <>
            <ModalDiv />
            <Table columns={columns} data={props.publicIpData ?? []} csvheaders={csvheaders} />
        </>
    );
}