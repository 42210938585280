import React, {ReactElement, useCallback, useRef, useState} from "react";
import Modal from "./Modal";
import {Table} from "react-table";

const CONTACTS_PER_GROUP = 450

const TYPE_SERVICE_OWNER = "service_owner";
const TYPE_DELEGATE = "delegate";

type Config = {
    enable?: boolean,
    fieldsNames?: { [key: string]: string }
};


class Contact {
    constructor(
      public email: string,
      public type: string,
      public sourceRow: string
    ) {}
}

const useContactGroups = (exportTable: Table, config: Config): [() => ReactElement, () => void] => {
    const [isOpen, setIsOpen] = useState(false);
    const content = useRef(null);

    const showGroupsCallback = useCallback(async () => {
        if (!config.fieldsNames) return;
        const contacts = exportTable.rows.map((row) => extractContacts(row, config)).flat()
        const uniqueContacts = [...contacts.reduce((map, contact) => map.set(contact.email, contact), new Map()).values()];
        const uniqueContactsGroups = divideIntoGroups(uniqueContacts, CONTACTS_PER_GROUP);

        content.current = (
            <>
                <h6>From <b>{exportTable.rows.length}</b> rows, there are <b>{uniqueContacts.length}</b> contacts split into <b>{uniqueContactsGroups.length}</b> groups of <b>{CONTACTS_PER_GROUP}</b></h6>
                {uniqueContactsGroups.map((contactGroup, i) =>
                    <textarea
                        key={i}
                        readOnly
                        style={{width: "100%", height: "100%", minHeight: "180px"}}
                        value={contactGroup.map((contact) => contact.email).join(', ')}
                    />
                )}
            </>
        )
        setIsOpen(true);
    }, [exportTable, config]);

    const ContactGroupsModalDiv = (): ReactElement => (
        <div id="contacts-groups-popup">
            {isOpen && <Modal setIsOpen={setIsOpen} content={content.current}/>}
        </div>
    )

    return [ContactGroupsModalDiv, showGroupsCallback];
}

export default useContactGroups;

const extractContacts = (row, config: Config) => {
    if (!config.fieldsNames) return;

    const ownerContact = extractServiceOwnerContact(row, config);
    const delegatesContact = extractDelegatesContact(row, config);
    return [ownerContact, ...delegatesContact].filter(Boolean);
}

const extractServiceOwnerContact = (row, config: Config) => {
    if (!config.fieldsNames || !row.original[config.fieldsNames.serviceOwnerEmail]) {
        return null;
    }
    return new Contact(row.original[config.fieldsNames.serviceOwnerEmail], TYPE_SERVICE_OWNER, row);
}

const extractDelegatesContact = (row, config: Config) => {
    if (!config.fieldsNames || !row.original[config.fieldsNames.delegates]) return [];

    return row.original[config.fieldsNames.delegates].map((delegate) => {
        if (!delegate[config.fieldsNames.delegatesEmail]) {
            return null;
        }
        return new Contact(delegate[config.fieldsNames.delegatesEmail], TYPE_DELEGATE, row)
    }).filter(Boolean);
}

const divideIntoGroups = (array, chunkSize) => {
    return array.reduce((resultArray, item, index) => {
        if (index % chunkSize === 0) {
            resultArray.push([])
        }
        resultArray[Math.floor(index / chunkSize)].push(item)
        return resultArray
    }, [])
}