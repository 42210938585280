import React, {useEffect, useRef, useState} from "react";
import "./MultipleElementFilter.css";

const NULL_VALUE = '<null>';

export default function MultipleElementFilter({column: {filterValue, setFilter, preFilteredRows, id}}): JSX.Element {

    const gatherOptions = (rows: typeof preFilteredRows, id: string) => {
        return rows.reduce((opts: Map<any, number>, row) => {
            const value = row.values[id] ?? NULL_VALUE;
            opts.set(value, (opts.get(value) || 0) + 1);
            return opts;
        }, new Map<any, number>());
    };

    const options = React.useMemo(
        () => Array.from(gatherOptions(preFilteredRows, id).entries()).sort((a, b) => b[1] - a[1]),
        [preFilteredRows, id]
    );

    const [show, setShow] = useState(false);

    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
              setShow(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleCheckboxClick = (e, option) => {
        e.stopPropagation();
        e.preventDefault();
        updateFilters(option, filterValue, setFilter);
    };

    const activeFilters = (filterValue || []).length;

    let displayText = getDisplayText(show, filterValue, options.length, activeFilters);

    const activeFiltersClass = (activeFilters) ? "active-filters" : "";

    return (
        <div ref={wrapperRef} onClick={() => setShow(!show)} className={`MultipleElementFilter ${activeFiltersClass}`}>
            <div>
                <span className={`filter-title`}>{displayText}</span>
                {show && (
                    <div className="expanded">
                        <button onClick={(e) => {
                            e.stopPropagation();
                            setShow(false);
                        }}>Close
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            setFilter([]);
                        }}>Reset
                        </button>
                        {options.map(([option, optionCount], i) => (
                            <div key={i}>
                                <label
                                    className={(filterValue || []).includes(option) ? "active" : ""}
                                    onClick={(e) => handleCheckboxClick(e, option)}
                                >
                                    <input
                                        type="checkbox"
                                        value={option === null ? NULL_VALUE : option}
                                        checked={(filterValue || []).includes(option)}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                    {option === null ? NULL_VALUE : `${option}`} {`(${optionCount})`}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

function updateFilters(option, filterValue, setFilter) {
    const set = new Set(filterValue || []);
    if (set.has(option)) {
        set.delete(option);
    } else {
        set.add(option);
    }
    setFilter(set.size > 0 ? Array.from(set) : undefined);
}

function getDisplayText(show: boolean, filterValue: any[], optionsLength: number, activeFilters: number): string {
    let displayText = `Available filters: ${optionsLength}`;
    if (show || filterValue && filterValue.length) {
        displayText = `Applied filters: ${activeFilters}`;
    }
    return displayText;
}

export function includesSome(rows, id, filterValue) {
    if(!filterValue || filterValue.length === 0) {
        return rows;
    }
    return rows.filter(row => {
        const rowValue = row.values[id];
        return (filterValue || []).includes(rowValue) || ((filterValue || []).includes('<null>') && rowValue === null);
    });
}