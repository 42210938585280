import { Card } from 'react-bootstrap';
import './UpcomingFeatures.css';

function UpcomingFeatures() {
    return (
        <div className='cardGrid'>
            <Card className='upcomingFeaturesItem'>
                <Card.Body>
                    <Card.Title>Instances in AWS that have no matching SSM Managed entry</Card.Title>
                    <Card.Text>
                        This report will list all the instances in AWS that are present in AWS, but have no corresponding
                        SSM Management record, or have a record with lost connection or error status
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default UpcomingFeatures;