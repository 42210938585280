import React, { useMemo } from 'react';
import  { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import "../component-css/SideBar.css"

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    background-color: #203143;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    text-decoration: none;
    font-size: 18px;
    font-weight:bold;
    
    &:hover {
        color: #FED41D;
    }
`;
const SideBarLabel = styled.span`
    margin-left: 15px;
    font-size: 14px;
`;    

const SubMenu = ({item, rootPath}) => {
    
    const location = useLocation();

    const isSelected = useMemo(() => {
        return location.pathname === `${rootPath}/${item.path}`;
    }, [rootPath, item.path, location]);

    const url = `${rootPath}/${item.path}`

    return (
        <div>
            <SidebarLink to = {url}>
                <div className={isSelected ? "active" : ""}>
                    <SideBarLabel>
                        {item.title}
                    </SideBarLabel>
                </div>
            </SidebarLink>     
        </div>
    );
}

export default SubMenu;