import { useEffect, useState, useContext } from "react";
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { loginRequest, protectedResources } from "../../components/common/authConfig";
import { callApiWithToken } from "../../components/common/fetch";
import { VpcAccessPointData } from "../../components/Reporting/VpcAccessPointItem";
import { SessionStoreContext } from "../../utils/SessionStore";
import { MSALConfig } from "../../config/msal.config";
import SearchListForm from "../../components/common/SearchListForm";
import PaginatedQuery from "../../utils/PaginatedQuery";

function FindVpcAccessPoint() {
    const sessionState = useContext(SessionStoreContext);
    const [searchFilter, setSearchFilter] = useState("");

    const onFilterForVpcAccessPoint = (returnedSearchFilter) => {
        sessionState.setItem("find-vpc-accesspoint-search-filter", returnedSearchFilter);
        setSearchFilter(returnedSearchFilter);
    };

    // Once session storage data has been fetched, update filter
    sessionState.useOnReady(() => {
        setSearchFilter(sessionState.getItem("find-vpc-accesspoint-search-filter", "EventLog"));
    });

    return (
        <div>
            <SearchListForm onFilter={onFilterForVpcAccessPoint} searchCriteriaName="VpcAccessPoint" type="vpcAccessPoint" />
            <VpcAccessPointItems props={searchFilter} />
        </div>
    );
}

export default FindVpcAccessPoint;

const VpcAccessPointContent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [results, setResults] = useState(null);
    const newQueryVar = props.props.props;

    const queryName = "listvpcAccessPoints";
    const queryString = `
        query ${queryName}($name: String!, $nextToken: String) {
            ${queryName}(AccountId: $name, nextToken: $nextToken) {
                items {
                    AccountId 
                    VpcAccessPoint
                    VpcAccessPointAlias
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiLogReporting.endpoint,
        protectedResources.apiLogReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none" && newQueryVar) {
                query.getFullResult(
                    account,
                    instance,
                    {name: newQueryVar}
                ).then(data => setResults(data))
            }
        },
        [account, inProgress, instance, newQueryVar]
    );

    console.log(results);
    return (
        <>
            {results ? <VpcAccessPointData vpcAccessPointData={results} /> : null}
        </>
    );
};

export const VpcAccessPointItems = (props) => {

    return (

        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={MSALConfig.loginRequest} >
            <VpcAccessPointContent props={props} />
        </MsalAuthenticationTemplate>
    )
};