import React from 'react';
import "../component-css/Modal.css"

const Modal = (props) => {

    let content;
    if (React.isValidElement(props.content)) {
        content = <div className="modalContent">{props.content}</div>;
    } else {
        content = <div className="modalContent" dangerouslySetInnerHTML={{ __html: String(props.content) }}></div>;
    }

    return (
        <>
            <div className="darkBG" onClick={() => props.setIsOpen(false)} />
            <div className="centered">
                <div className="mymodal">
                    <h5 className="heading">Details</h5>
                    {content}
                </div>
            </div>
        </>
    );
}

export default Modal;