import React from "react";
import Table from "../common/Tables";

export const P2TopIPsData = (props) => {

    const columns = React.useMemo(
        () => [
                {
                    Header: 'SourceIP',
                    accessor: 'sourceip',
                    filter: 'fuzzyText',
                    sortType: 'basic',
                },
                {
                    Header: 'TerminatingRuleId',
                    accessor: 'terminatingruleid',
                    filter: 'fuzzyText',
                    sortType: 'basic',
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                    sortType: 'basic',
                },
                {
                    Header: 'MinDatetime',
                    accessor: 'min_datetime',
                    sortType: 'basic',
                },
                {
                    Header: 'MaxDatetime',
                    accessor: 'max_datetime',
                    sortType: 'basic',
                },
                {
                    Header: 'Count',
                    accessor: 'count',
                    sortType: 'basic',
                },
                {
                    Header: 'WebAclId',
                    accessor: 'webaclid',
                    filter: 'fuzzyText',
                    sortType: 'basic',
                },
            ],
        []);

        const csvheaders = React.useMemo(
            () => [
                    {
                        label: 'SourceIP',
                        key: 'sourceip',
                    },
                    {
                        label: 'TerminatingRuleId',
                        key: 'terminatingruleid',
                    },
                    {
                        label: 'Action',
                        key: 'action',
                    },
                    {
                        label: 'MinDatetime',
                        key: 'min_datetime',
                    },
                    {
                        label: 'MaxDatetime',
                        key: 'max_datetime',
                    },
                    {
                        label: 'Count',
                        key: 'count',
                    },
                    {
                        label: 'WebAcliId',
                        key: 'webaclid',
                    },
                ],
            []);
            console.log(props);
    return (
        <>
            <Table columns={columns} data={props.p2TopIPsData ?? []} csvheaders={csvheaders} />
        </>
    );
}