import React from "react";
import Table from "../common/Tables";

export const VpcAccessPointData = (props) => {

    const columns = React.useMemo(
        () => [
                {
                    Header: 'Account Id',
                    accessor: 'AccountId',
                    sortType: 'basic',
                },
                {
                    Header: 'Vpc AccessPoint Name',
                    accessor: 'VpcAccessPoint',
                    sortType: 'basic',
                },
                {
                    Header: 'Vpc AccessPoint Alias',
                    accessor: 'VpcAccessPointAlias',
                    sortType: 'basic',
                },
            ],
        []);

        const csvheaders = React.useMemo(
            () => [
                    {
                        label: 'Account Id',
                        key: 'AccountId',
                    },
                    {
                        label: 'Vpc AccessPoint Name',
                        key: 'VpcAccessPoint',
                    },
                    {
                        label: 'Vpc AccessPoint Alias',
                        key: 'VpcAccessPointAlias',
                    },
                ],
            []);
    
            console.log(props);
    return (
        <>
            <Table columns={columns} data={props.vpcAccessPointData ?? []} csvheaders={csvheaders} />
        </>
    );
}