import { useEffect, useState, useContext } from "react";
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { loginRequest, protectedResources } from "../../components/common/authConfig";
import { callApiWithToken } from "../../components/common/fetch";
import { ServiceData } from "../../components/Reporting/ServiceItem";
import { SessionStoreContext } from "../../utils/SessionStore";
import { MSALConfig } from "../../config/msal.config";
import SearchListForm from "../../components/common/SearchListForm";
import PaginatedQuery from "../../utils/PaginatedQuery";

function FindService() {
    const sessionState = useContext(SessionStoreContext);
    const [searchFilter, setSearchFilter] = useState("");

    const onFilterForService = (returnedSearchFilter) => {
        sessionState.setItem("find-service-search-filter", returnedSearchFilter);
        setSearchFilter(returnedSearchFilter);
    };

    // Once session storage data has been fetched, update filter
    sessionState.useOnReady(() => {
        setSearchFilter(sessionState.getItem("find-service-search-filter", "EventLog"));
    });

    return (
        <div>
            <SearchListForm onFilter={onFilterForService} searchCriteriaName="Service" type="service" />
            <ServiceItems props={searchFilter} />
        </div>
    );
}

export default FindService;

const ServiceContent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [results, setResults] = useState(null);
    const newQueryVar = props.props.props;

    const queryName = "listServices";
    const queryString = `
        query ${queryName}($name: String!, $nextToken: String) {
            ${queryName}(name: $name, nextToken: $nextToken) {
                items {
                    name 
                    resourceid
                    accountid
                    status
                    servicetype
                    servicesdependon
                    displayname
                    dependentservices
                    starttype
                    capturetime
                    region                
                    resourcetype
                    instancestatus
                    agentversion
                    platformtype
                    platformversion
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiReporting.endpoint,
        protectedResources.apiReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none" && newQueryVar) {
                // Result Set for Services is too large, so only load one page until we have a better solution
                // We could potentially load pages as the user moves through the pages in the table
                query.getFirstPage(
                    account,
                    instance,
                    {name: newQueryVar}
                ).then(data => setResults(data))
            }
        },
        [account, inProgress, instance, newQueryVar]
    );

    console.log(results);
    return (
        <>
            {results ? <ServiceData serviceData={results} /> : null}
        </>
    );
};

export const ServiceItems = (props) => {

    return (

        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={MSALConfig.loginRequest} >
            <ServiceContent props={props} />
        </MsalAuthenticationTemplate>
    )
};