import axios from "axios";

export const callApiWithToken = async (idToken, apiEndpoint, query) => {
    const bearer = `${idToken}`;
    const body = `${query}`;
    const options = {
      headers: {
        "Authorization": bearer,
        "Accept": "application/json",
        "Content-Type": "application/graphql",
      },
      credentials: "omit",
    };
  
    try {
      const response = await axios.post(apiEndpoint, body, options);
      return response.data;
    } catch (error) {
      console.error('Request failed:', error);
      if (error.response) {
        console.error('Server responded with error data:', error.response.data);
        console.error('Error Status:', error.response.status);
        console.error('Error Headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      throw error; 
    }
  };  