import styled from "styled-components";

import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

import "../component-css/SideBar.css"
import { useState } from "react";

import reportingData from "../../utils/data/ReportingSideBarData.json";
import certData from "../../utils/data/CertSideBarData.json";
import supportData from "../../utils/data/SupportSideBarData.json"

const SidebarNav = styled.nav`
width: 300px;
height: 100%;
display: flex;
justify-content: start;
flex-direction: column;
top: 20;
left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
transition: 350ms;
z-index: 10;
background: #203143;
`;

const SidebarWrap = styled.div`
width: 100%;
margin-top: 5px;
top: 5px;
`;

const SideBar = () => {
	const sidebar = true;
	const [hidden, setHidden] = useState(false);

	return hidden ? <>
		<div className="sidebar-min">
			<div className="sidebar-button" style={{margin: "0"}} onClick={() => setHidden(false)}>»</div> 
		</div>
	</> : <>
		<IconContext.Provider value={{ color: "green" }}>
			<SidebarNav sidebar={sidebar}>
				<SidebarWrap id="sidebar-div">
					<div className="close-container">
						<div className="sidebar-button" onClick={() => setHidden(true)}>«</div>
					</div>
					
                    <div className="sidebar-header">Reporting</div>

                    {reportingData.SideBarItems.map((item, index) => {
						return item.display ? <SubMenu item={item} key={index} rootPath="/Reporting"/> : null;
					})}

                    <div className="sidebar-header">CERT</div>
                    
                    {certData.SideBarItems.map((item, index) => {
						return item.display ? <SubMenu item={item} key={index} rootPath="/cert"/> : null;
					})}

					<div className="sidebar-header">Help & Support</div>
                    
                    {supportData.SideBarItems.map((item, index) => {
						return item.display ? <SubMenu item={item} key={index} rootPath="/support"/> : null;
					})}
				</SidebarWrap>
			</SidebarNav>
		</IconContext.Provider>
	</>;
};

export default SideBar;
