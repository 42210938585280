import { Card } from 'react-bootstrap';
import './SnagList.css';

function SnagList() {
    return (
        <div className='cardGrid'>
            <Card className='snagListItem'>
                <Card.Body>
                    <Card.Title>Resizing stopped working on Data Tables</Card.Title>
                    <Card.Text>
                        Recent package update has caused the data table resizing to stop working
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className='snagListItem'>
                <Card.Body>
                    <Card.Title>Public IP Report does not track Public IP changes that happen in a single day</Card.Title>
                    <Card.Text>
                        If a public ip changes during the course of the day, only the last assigned AWS resource will be listed in the report
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default SnagList;