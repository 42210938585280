import React from 'react';
import '../component-css/Footer.css';  

const Footer = () => {
    return (
        <>
            <div className ="t2"><span className="title2">Hidden Spacer</span></div>
            <div className ="t2" style={{position: "fixed"}}>   
                <span className = "title2" style={{marginBottom: "0px"}}>
                    <b>@1999-2022 | <a className='plain' href='https://www.bp.com/'>BP</a> | <a className='plain' href='https://www.bp.com/en/global/corporate/privacy-statement.html'>Privacy</a> | <a className="plain" href="https://www.bp.com/en/global/corporate/legal-notice.html">Disclaimer</a></b>
                </span>
            </div>
        </>
    );
    // first div is in the document flow and creates a space for the navbar to go
    // when fully scrolled down to avoid overlapping the content
    
    // second div is the actual navbar, given position fixed 

};
export default Footer;