import React from "react";
import { VscWarning } from "react-icons/vsc"

export const ContentErrorComponent = () => (
    <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        flexGrow: "1", 
        flexDirection: "column",
        fontSize: "x-large",
    }}>
        <VscWarning size="5em" />
        Something Went Wrong
    </div>
)

export class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
        // Display fallback UI    
        this.setState({ hasError: true });  
        console.log("Error Handler", error, info);
    }

    render() {
        if (this.state.hasError)
            return this.props.errorComponent;
        
        return this.props.children;
    }
  }