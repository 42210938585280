import React from "react";
import Table from "../common/Tables";

export const ApplicationData = (props) => {

    const columns = React.useMemo(
        () => [
                {
                    Header: 'App Name',
                    accessor: 'name',
                    filter: 'fuzzyText',
                    sortType: 'basic',
                },
                {
                    Header: 'InstanceId',
                    accessor: 'resourceid',
                    sortType: 'basic',
                },
                {
                    Header: 'Application Type',
                    accessor: 'applicationtype',
                    sortType: 'basic',
                },
                {
                    Header: 'Installation Time',
                    accessor: 'installedtime',
                    sortType: 'basic',
                },
                {
                    Header: 'Architecture',
                    accessor: 'architecture',
                    sortType: 'basic'
                },
                {
                    Header: 'Summary',
                    accessor: 'summary',
                    sortType: 'basic',
                },
                {
                    Header: 'Account Id',
                    accessor: 'accountid',
                    sortType: 'basic',
                },
                {
                    Header: 'Package Id',
                    accessor: 'packageid',
                    sortType: 'basic',
                },
                {
                    Header: 'Publisher',
                    accessor: 'publisher',
                    sortType: 'basic',
                },
                {
                    Header: 'App Version',
                    accessor: 'version',
                    sortType: 'basic',
                },
                {
                    Header: 'App Release',
                    accessor: 'release',
                    sortType: 'basic',
                },
                {
                    Header: 'URL',
                    accessor: 'url',
                    sortType: 'basic',
                },
                {
                    Header: 'Capture Time',
                    accessor: 'capturetime',
                    sortType: 'basic',
                },
                {
                    Header: 'Epoch',
                    accessor: 'epoch',
                    sortType: 'basic',
                },
                {
                    Header: 'AWS Region',
                    accessor: 'region',
                    sortType: 'basic',
                },
                {
                    Header: 'Instance Status',
                    accessor: 'instancestatus',
                    sortType: 'basic',
                },
                {
                    Header: 'Agent Version',
                    accessor: 'agentversion',
                    sortType: 'basic',
                },
                {
                    Header: 'Platform Type',
                    accessor: 'platformtype',
                    sortType: 'basic',
                },
                {
                    Header: 'Platform Version',
                    accessor: 'platformversion',
                    sortType: 'basic',
                },
                {
                    Header: 'Tag Key',
                    accessor: 'tagkey',
                    sortType: 'basic',
                },
                {
                    Header: 'Tag Value',
                    accessor: 'tagvalue',
                    sortType: 'basic',
                }
            ],
        []
    );

    const csvheaders = React.useMemo(
        () => [
                {
                    label: 'App Name',
                    key: 'name',
                },
                {
                    label: 'InstanceId',
                    key: 'resourceid',
                },
                {
                    label: 'Application Type',
                    key: 'applicationtype',
                },
                {
                    label: 'Installation Time',
                    key: 'installedtime',
                },
                {
                    label: 'Architecture',
                    key: 'architecture',
                },
                {
                    label: 'Summary',
                    key: 'summary',
                },
                {
                    label: 'Account Id',
                    key: 'accountid',
                },
                {
                    label: 'Package Id',
                    key: 'packageid',
                },
                {
                    label: 'Publisher',
                    key: 'publisher',
                },
                {
                    label: 'App Version',
                    key: 'version',
                },
                {
                    label: 'App Release',
                    key: 'release',
                },
                {
                    label: 'URL',
                    key: 'url',
                },
                {
                    label: 'Capture Time',
                    key: 'capturetime',
                },
                {
                    label: 'Epoch',
                    key: 'epoch',
                },
                {
                    label: 'AWS Region',
                    key: 'region',
                },
                {
                    label: 'Instance Status',
                    key: 'instancestatus',
                },
                {
                    label: 'Agent Version',
                    key: 'agentversion',
                },
                {
                    label: 'Platform Type',
                    key: 'platformtype',
                },
                {
                    label: 'Platform Version',
                    key: 'platformversion'
                },
                {
                    label: 'Tag Key',
                    key: 'tagkey'
                },
                {
                    label: 'Tag Value',
                    key: 'tagvalue'
                }
            ],
        []
    );

    return (
        <>
            <Table columns={columns} data={props.applicationData ?? []} csvheaders={csvheaders} />
        </>
    );
}