import React, { useState, useEffect, useContext } from 'react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

// import KBForm from '../../components/Reporting/FindKB/KBForm'
import { SessionStoreContext } from '../../utils/SessionStore'
import { MSALConfig } from '../../config/msal.config'
import { KbSearchDisplayController } from '../../components/Reporting/FindKB/KbReportsUI'
// import { LiveKBsReport } from './LiveKBsReport'


export const KbReportUI = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={MSALConfig.loginRequest}
    >
   <KbSearchDisplayController />
    </MsalAuthenticationTemplate>
  )
}

export default KbReportUI;