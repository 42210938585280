import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { MsalProvider } from '@azure/msal-react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import Home from './pages/Home'
import Logout from './utils/Logout'
import { PopupProvider, PopupList /*PopupContext*/ } from './components/component-pages/Popup'
import Header from './components/component-pages/Header'
import Footer from './components/component-pages/Footer'
import NaviBar from './components/component-pages/NaviBar'

import Cert from './pages/cert/Cert'
import Form from './components/component-pages/Form'
import ForensicAnalysis from './components/component-pages/ForensicAnalysis'

import Reporting from './pages/reporting/Reporting'
import FindApplications from './pages/reporting/FindApplications'
import FindServices from './pages/reporting/FindServices'
import FindPublicIp from './pages/reporting/FindPublicIps'
import FindP2TopIPs from './pages/reporting/FindP2TopIPs'
import FindWafResources from './pages/reporting/FindWafResources'
import FindWafAccessPoints from './pages/reporting/FindWafAccessPoints'
import FindVpcAccessPoints from './pages/reporting/FindVpcAccessPoints'
import FindCtrAccessPoints from './pages/reporting/FindCtrAccessPoints'
import KbReportUI from './pages/reporting/FindKBs'
import AwsAccountsPage from './pages/reporting/AwsAccountsDetails'
import CloudEnvironmentsPage from './pages/reporting/CloudEnvironmentsDetails'

import Support from './pages/support/Support'
import SnagList from './pages/support/SnagList'
import UpcomingFeatures from './pages/support/UpcomingFeatures'

import { ContentErrorComponent, ErrorHandler } from './utils/ErrorHandler'
import { SessionStoreProvider } from './utils/SessionStore'
import { MSALConfig } from './config/msal.config'
import './App.css'
import { ApolloMsalProvier } from './providers/apollo-provider'

function App({ instance }) {
  return (
    <Router>
      <MsalProvider instance={instance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={MSALConfig.loginRequest}
        >
          <ApolloMsalProvier>
            <div className="flex-container">
              <SessionStoreProvider storekey="aws-sec-portal-prefs">
                <PopupProvider>
                  <div id="header-navbar">
                    <Header />
                    <NaviBar />
                  </div>

                  <ErrorHandler errorComponent={<ContentErrorComponent />}>
                    <main id="content-container">
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="cert" element={<Cert />}>
                          <Route path="form" element={<Form />} />
                          <Route path="finstances" element={<ForensicAnalysis />} />
                        </Route>
                        <Route path="/Reporting" element={<Reporting />}>
                          <Route path="FindApplications" element={<FindApplications />} />
                          <Route path="FindServices" element={<FindServices />} />
                          <Route path="FindPublicIps" element={<FindPublicIp />} />
                          <Route path="FindP2TopIPs" element={<FindP2TopIPs />} />
                          <Route path="FindWafResources" element={<FindWafResources />} />
                          <Route path="FindWafAccessPoints" element={<FindWafAccessPoints />} />
                          <Route path="FindVpcAccessPoints" element={<FindVpcAccessPoints />} />
                          <Route path="FindCtrAccessPoints" element={<FindCtrAccessPoints />} />
                          <Route path="FindKBs" element={<KbReportUI />} />
                          <Route path="AwsAccountsDetails" element={<AwsAccountsPage />} />
                          <Route path="CloudEnvironmentsDetails" element={<CloudEnvironmentsPage />} />
                        </Route>
                        <Route path="/support" element={<Support />}>
                          <Route path="snag-list" element={<SnagList />} />
                          <Route path="upcoming-features" element={<UpcomingFeatures />} />
                        </Route>
                        <Route path="logout" element={<Logout />} />
                      </Routes>
                    </main>
                  </ErrorHandler>

                  <div id="footer">
                    <Footer />
                  </div>

                  <div id="error-popup">
                    <PopupList />
                  </div>
                </PopupProvider>
              </SessionStoreProvider>
            </div>
          </ApolloMsalProvier>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Router>
  )
}

export default App