import React from "react";
import Table from "../common/Tables";
import useDelegateMembersRenderer from "../common/DelegateMembers";
import MultipleElementFilter, {includesSome} from "../common/MultipleElementFilter";

export const AwsAccountData = (props) => {
    const [DelegateMembersCellRenderer] = useDelegateMembersRenderer();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Account ID',
                accessor: 'account_id',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'Account Name',
                accessor: 'account_name',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'Status',
                accessor: 'status',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Account Type',
                accessor: 'account_type',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Environment Type',
                accessor: 'environment_type',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Short Description',
                accessor: 'short_description',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'AWS Joined Method',
                accessor: 'aws_joined_method',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'AWS Joined Date',
                accessor: 'aws_joined_date',
                sortType: 'basic',
            },
            {
                Header: 'AWS Platform Status',
                accessor: 'aws_platform_status',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'OrgMaster Account ID',
                accessor: 'aws_organisation_master_account_id',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Organisation Type',
                accessor: 'aws_organisation_type',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Organisation Name',
                accessor: 'aws_organisation_name',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'IT Service Name',
                accessor: 'it_service_name',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'IT Portfolio Name',
                accessor: 'it_service_portfolio_name',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'IT Service Owner Name',
                accessor: 'it_service_owner_name',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'IT Service Owner NTID',
                accessor: 'it_service_owner_ntid',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'IT Service Owner Email',
                accessor: 'it_service_owner_email',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'Delegates Group Name',
                accessor: 'delegates_group_name',
                sortType: 'basic',
            },
            {
                Header: 'Delegates Members',
                accessor: 'delegates_members',
                Cell: DelegateMembersCellRenderer,
            },
            {
                Header: 'Security Tooling Deploy',
                accessor: 'security_tooling_deploy',
                sortType: 'basic',
            },
            {
                Header: 'Global RBAC Deployed',
                accessor: 'global_rbac_deployed',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'PCI',
                accessor: 'pci',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Internet Facing',
                accessor: 'internet_facing',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
        ],
        [DelegateMembersCellRenderer]
    );

    const csvheaders = React.useMemo(
        () => [
            {
                label: 'Account ID',
                key: 'account_id',
            },
            {
                label: 'Account Name',
                key: 'account_name',
            },
            {
                label: 'Status',
                key: 'status',
            },
            {
                label: 'Account Type',
                key: 'account_type',
            },
            {
                label: 'Environment Type',
                key: 'environment_type',
            },
            {
                label: 'Short Description',
                key: 'short_description',
            },
            {
                label: 'AWS Joined Method',
                key: 'aws_joined_method',
            },
            {
                label: 'AWS Joined Date',
                key: 'aws_joined_date',
            },
            {
                label: 'AWS Platform Status',
                key: 'aws_platform_status',
            },
            {
                label: 'Organisation Master Account ID',
                key: 'aws_organisation_master_account_id',
            },
            {
                label: 'Organisation Type',
                key: 'aws_organisation_type',
            },
            {
                label: 'Organisation Name',
                key: 'aws_organisation_name',
            },
            {
                label: 'IT Service Name',
                key: 'it_service_name',
            },
            {
                label: 'IT Portfolio Name',
                key: 'it_service_portfolio_name',
            },
            {
                label: 'IT Service Owner Name',
                key: 'it_service_owner_name',
            },
            {
                label: 'IT Service Owner NTID',
                key: 'it_service_owner_ntid',
            },
            {
                label: 'IT Service Owner Email',
                key: 'it_service_owner_email',
            },
            {
                label: 'Delegates Group Name',
                key: 'delegates_group_name',
            },
            {
                label: 'Delegates Members',
                key: 'delegates_members',
            },
            {
                label: 'Security Tooling Deploy',
                key: 'security_tooling_deploy',
            },
            {
                label: 'Global RBAC Deployed',
                key: 'global_rbac_deployed',
            },
            {
                label: 'PCI',
                key: 'pci',
            },
            {
                label: 'Internet Facing',
                key: 'internet_facing',
            },
        ],
        []
    );

    return (
        <>
            <Table
                columns={columns}
                data={props.awsAccountData ?? []}
                csvheaders={csvheaders}
                contactsGroupConfiguration={{
                    enable: true,
                    fieldsNames: {
                        serviceOwnerEmail: "it_service_owner_email",
                        serviceOwnerName: "it_service_owner_name",
                        delegates: "delegates_members",
                        delegatesEmail: "email",
                        delegatesName: "name",
                    }
                }}
            />
        </>
    );
}