import { setContext } from '@apollo/client/link/context'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
    IPublicClientApplication,
    // PublicClientApplication,
} from '@azure/msal-browser'
import { MSALConfig } from '../../../../config/msal.config'

import { protectedResources } from '../../../../components/common/authConfig';
// import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";


export const MSALAsyncTokenLookup = async (instance: IPublicClientApplication, inProgress: InteractionStatus, accounts: AccountInfo[]) => {
    const account = accounts[0] || null;

    console.log('MSALAsyncTokenLookup', { instance, inProgress, account });

    if (account && (inProgress === InteractionStatus.None || inProgress === InteractionStatus.Startup)) {
        try {
            const result = await instance.acquireTokenSilent({
                // scopes: protectedResources.apiRealtime.scopes,
                scopes: protectedResources.apiReporting.scopes,
                account,
            })

            return result.idToken
        } catch (err) {
            console.error(err)
            if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(MSALConfig.loginRequest)
            }
        }
    } else if (!account && inProgress === InteractionStatus.None) {
        console.log(`MSAL Apollo: try get token with redirect`)
        return instance.acquireTokenRedirect(MSALConfig.loginRequest)
    } else {
        throw new Error(
            `cannot get token from MSAL instance; Account is ${JSON.stringify(
                account
            )} and interacion status is ${inProgress}`
        )
    }

    return null
}

export const MSAL_AUTH_HEADER = 'Authorization'

export const createMSALAuthLink = (msalInstance: IPublicClientApplication, inProgress: InteractionStatus, accounts: AccountInfo[]) =>
    setContext(async (_, { headers }) => {
        console.log('createMSALAuthLink', { msalInstance, inProgress, accounts });
        const token = await MSALAsyncTokenLookup(msalInstance, inProgress, accounts)

        return {
            headers: {
                ...headers,
                [MSAL_AUTH_HEADER]: token ? `Bearer ${token}` : null,
            },
        }
    })

export const getMSALWSAuthParams = async (msalInstance: IPublicClientApplication, inProgress: InteractionStatus, accounts: AccountInfo[]) => {
    console.log('getMSALWSAuthParams', inProgress, accounts);
    
    const token = await MSALAsyncTokenLookup(msalInstance, inProgress, accounts)

    return {
        [MSAL_AUTH_HEADER]: token ? `Bearer ${token}` : null,
    }
}