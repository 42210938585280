import React, { useState, useEffect, useContext, useCallback } from 'react'
import { PopupContext } from '../../component-pages/Popup'
import { useApolloClient, useLazyQuery, useQuery, useSubscription } from '@apollo/client'
import { useDebounce } from '../../../hooks/useDebounce'
import { gql_sub_kb_list_publish } from '../../graphql/subscriptions'
import { get_kbs_list } from '../../graphql/queries'
import { styled } from '../../../constants/app.constants';
import { createMSALAuthLink, getMSALWSAuthParams } from '../../../graphql/apollo/client/auth-links/msal'
import Table from "../../common/Tables";


const validSearchExp = /^KB\d+$/;
const isValidSearch = (x: string) => validSearchExp.test(x);

const TagsInput = styled.input`
 paddding: 3px 5px;
 margin: 10px 0;
`;

export const KbSearchDisplayController = () => {
  const popup = useContext(PopupContext).addPopup;
  console.log('Subscribing to results...')
  const { data: subsData, loading: subLoading, error: subError } = useSubscription(gql_sub_kb_list_publish)

  const [textTags, setInput] = useState('');

  let [error, setError] = useState(null);

  const tags = useDebounce(textTags, 400);

  const client = useApolloClient();

  const [processing, setProcessing] = useState(false)

  const [triggerReportBuild, { data: reportFinalData }] = useLazyQuery(
    get_kbs_list
  )

  const startQuery = useCallback(
    async (tags: string) => {
      if (!processing) {
        setProcessing(true)
        try {
          console.log(`Started query with tags: ${tags}`)

          triggerReportBuild({
            variables: {
              kb_list: tags
            }
          });
        } catch (e) {
          console.error("DID NOT SEND QUERY...")
          console.error(e)
          setError(e)
        } finally {
          setProcessing(false)
        }
      }
    }, [processing])

  useEffect(() => {
    // split to any comas
    if (tags?.length) {
      const kbList = tags.split(',').filter(isValidSearch);
      console.log(`Starting query with kbList: ${kbList}`)

      if (kbList?.length) {
        startQuery(kbList)
      }
    }

  }, [tags])


  const [reportData, setReportData] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState(null);

  const fetchReport = useCallback(async (url) => {
    const res = await fetch(url);

    if (res.ok) {
      const data = await res.text();

      // parse data from csv to json
      const headers = data.split('\n')[0].split(',');
      const formattedHeaders = [];
      const csvHeaders = [];
      const json = [];

      headers && headers.filter(header => !!header).forEach((header) => {
        const Header = header
          .replace(/_/g, ' ')
          .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());

        const label = Header;
        const accessor = header;
        const sortType = 'basic';

        formattedHeaders.push({ Header, accessor, sortType });
        csvHeaders.push({ label, key: accessor });
      });

      setHeaders(formattedHeaders);
      setCsvHeaders(csvHeaders);

      const rows = data.split('\n').slice(1);

      rows
        .map((row) => {
          const rawValues = row.split(',');
          const values = [];

          let index = 0;

          do {
            let value = rawValues[index];

            if (value.startsWith('"')) {
              do {
                index += 1;
                value += `,${rawValues[index]}`;
              } while (!rawValues[index].endsWith('"'));

              value = value.replace(/"/g, '');
            }

            values.push(value);
            index += 1;
          } while (index < rawValues.length);


          const obj = {};

          headers.map((key, i) => {
            obj[key] = values[i];
          });

          json.push(obj);
        });

      const filteredJson = json.filter((item) => {
        const keys = Object.keys(item);

        return keys.some((key) => !!item[key]);
      });

      console.log(`data received`, headers, json);
      setReportData(json);
    } else {
      setError(new Error(`Unable to fetch report from presigned URL`))
    }
  }, []);


  useEffect(() => {
    const uri = subsData?.onPublishResult?.report;
    if (uri && !reportData) {
      // fetch the data from the report
      fetchReport(uri)
    }
  }, [subsData?.onPublishResult?.report, reportData])



  const showError = error || subError;
  if (showError) {
    popup('error', showError)
    return <div>An error </div>
  }

  return (
    <div>
      <TagsInput className='text-lg' onChange={e => setInput(e.target.value)} />
      {subLoading ? <div>loading...</div> : (!!reportData ?
        <Table columns={headers} data={reportData} csvheaders={csvHeaders} /> :
        (showError ? <div>Error: {JSON.stringify(showError)} error {JSON.stringify(subError)}</div> :
          !!textTags?.length ? <div>Unable to find report data</div> : <div></div>))
      }
    </div>
  )
}

/**
[
"",
  "service_portfolio_name",
  "state",
  "last_update_date",
  "accountid",
  "instanceid",
  "owner_name",
  "patch_status_date",
  "delegate_group",
  "ce_name",
  "owner_email",
  "patch_status",
  "platformname",
  "platformversion",
  "agenttype",
  "agentversion",
  "computername",
  "ipaddress",
  "resourcetype",
  "platformtype",
  "mi_capturetime",
  "region",
  "KB4592471",
  "Compliant"
]*/