import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createWebsocketLink } from './ws-client'
import { GQLAuth, USE_GQL_AUTH } from './select-auth'
import { attachAppsSyncAuthLinks } from './auth-links/appsync'
import { createMSALAuthLink, getMSALWSAuthParams } from './auth-links/msal'
import { createHttpLink } from '@apollo/client/link/http'
import { split } from '@apollo/client/link/core'
import { GQLHTTPEndpoint } from '../../../constants/app.constants'
import { InteractionStatus, IPublicClientApplication } from '@azure/msal-browser'
// import { useMsal } from '@azure/msal-react'
import { AccountInfo } from "@azure/msal-browser";

// @ts-ignore
const isMSALAuth = USE_GQL_AUTH === GQLAuth.MSAL

const httpLink = createHttpLink({
    uri: GQLHTTPEndpoint,
})

// split based on operation type
const linkSplitter = ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
}
const createSplitLink = async (msal, inProgress, accounts) =>
    isMSALAuth
        ? split(
            linkSplitter,
            createWebsocketLink(await getMSALWSAuthParams(msal, inProgress, accounts)),
            httpLink
        )
        : httpLink

export namespace ApolloFactory {
    let client: ApolloClient<NormalizedCacheObject>

    export const createMSALAuthClient = async(instance: IPublicClientApplication, inProgress: InteractionStatus, accounts: AccountInfo[]) => {
        if (client) {
            return client
        }

        const link = await createSplitLink(instance, inProgress, accounts)
        
        client = new ApolloClient({
            // in both cases of auth we use the same HttpLink but we attach a different auth link
            link: !isMSALAuth
                ? attachAppsSyncAuthLinks(link)
                : createMSALAuthLink(instance, inProgress, accounts).concat(link),
            cache: new InMemoryCache(),
        })

        return client
    }
}