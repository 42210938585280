import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { GQLHTTPEndpoint } from '../../../constants/app.constants'
import { MSAL_AUTH_HEADER } from './auth-links/msal';
import { AppConfig } from '../../../config/app.config';

import { WebSocketLink } from "@apollo/client/link/ws";
import { ClientOptions, SubscriptionClient } from 'subscriptions-transport-ws';

import { print } from 'graphql/language/printer';

const base64encode = (str) => {
    return Buffer.from(str).toString('base64');
}

export const createWebsocketLink = (connectionParams) => {
    const host = GQLHTTPEndpoint.replace('https://', '').replace('http://', '').replace('/graphql', '');
    const wssEndpoint = GQLHTTPEndpoint.replace('http://', 'ws://').replace('https://', 'wss://').replace('appsync-api', 'appsync-realtime-api');
    const token = connectionParams[MSAL_AUTH_HEADER] ?? null;

    const OIDC_header = JSON.stringify({
        host,
        Authorization: token.replace('Bearer ', ''),
    });

    const OIDC_payload = JSON.stringify({
        connectionTimeoutMs: 10000,
    });

    const url = `${wssEndpoint}?header=${base64encode(OIDC_header)}&payload=${base64encode(OIDC_payload)}`;

    const middleware = {
        applyMiddleware: async (options, next) => {
            console.log('middleware', options);
            if (options.query) {
                const header = base64encode(OIDC_header);

                options.data = JSON.stringify({
                    query:
                        typeof options.query === 'string'
                            ? options.query
                            : print(options.query),
                    variables: options.variables,
                });

                options.extensions = {
                    authorization: {
                        ...JSON.parse(OIDC_header),
                    },
                };
            }

            next();
        },
    };

    const client = new SubscriptionClient(url, {
        reconnect: true,
        timeout: 300000,
    })

    client.use([middleware]);

    client.onError((err) => {
        console.warn('client error', err);
    });

    const link = new WebSocketLink(client);

    return link;
}

