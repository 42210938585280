import React,{ useState, useEffect } from 'react';
import '../component-css/Form.css';
import { useMsal, useAccount } from "@azure/msal-react";
import  Spinner from '../../images/Spinner.gif';
import axios from 'axios';
import { protectedResources } from "../common/authConfig";
import { callApiWithToken } from "../../certapi/fetchCert";
import { callDynamoDB } from "../../certapi/fetchCertDdb";
import '../component-css/FormTable.css';

export default function Form() {

    const [data, setData] = useState({investigation_id : "00000000000", instance_id : "i-00000000000000000", os_type1 : "Windows", delete_stack : "empty"});
    const [outputFlag, setOutputFlag] = useState(false);
    const [status, setStatus] = useState("Initiated");
    const [message, setMessage] = useState("Initiated")
    const [isLoading, setIsLoading] = useState(false);
    const [ exectId, setExecId] = useState();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const statusMap = new Map([
       ["initiating", "initiated"],
       ["existInstanceDetail", "Instance found and process begin"],
       ["noInstanceDetail", "Instance detail not exist in Snow Table"],
       ["copyInitialSnaphot", "copy of Initial Snapshot creation started"],
       ["cfnCreationInitiated", "Stack creation for New Instance begin"],
       ["errorProcessingTheInstance", "There has been an unexpected error processing this request. Reach out to dev team for future assistance"],
       ["complete", "Completed"]
    ]);
    let apigwAuthorizationTokenRequestLock = null; // Singleton-like storage for the MSAL promise to avoid multiple token requests
    let investigationStatusRefreshInterval;

    useEffect(() => {
        if (window.sessionStorage.getItem('investigation_id')===null) {
            // No active investigation saved in local storage
            return
        }

        let inv_id = window.sessionStorage.getItem('investigation_id')===null?data.investigation_id: window.sessionStorage.getItem('investigation_id');
        let ins_id = window.sessionStorage.getItem('instance_id')===null?data.instance_id: window.sessionStorage.getItem('instance_id');
        let os_type1 = window.sessionStorage.getItem('os_type1')===null?data.os_type1: window.sessionStorage.getItem('os_type1');
        let del_stk = window.sessionStorage.getItem('delete_stack')===null?data.delete_stack:window.sessionStorage.getItem('delete_stack');
        setData({investigation_id: inv_id, instance_id: ins_id, os_type1: os_type1, delete_stack: del_stk});
        setExecId(window.sessionStorage.getItem('exectId'));
        window.sessionStorage.getItem('progress') && setStatus(window.sessionStorage.getItem('progress'));
        setMessage(statusMap.get(window.sessionStorage.getItem('progress')));

        setOutputFlag(window.sessionStorage.getItem('outputFlag') === "true")
        setIsLoading(window.sessionStorage.getItem('isLoading') === "true")

        // once the page is mounted, if there is an investigation saved in sessionStorage, immediately fetch latest status
        fetchInvestigationStatus(protectedResources.apiCertDdb.endpoint, inv_id)
        startInvestigationStatusRefresh(protectedResources.apiCertDdb.endpoint, inv_id);

        return () => {
            clearInvestigationStatusRefresh()
        }
    },[]);

    async function getAPIGWAuthorizationToken() {
        const storedToken = window.sessionStorage.getItem('apigw_authorization_token');

        if (storedToken) {
            console.log("Token retrieved from sessionStorage:", storedToken);
            return storedToken;
        }

        console.log("No token found in sessionStorage, acquiring a new token.");
        apigwAuthorizationTokenRequestLock = updateAPIGWAuthorizationToken(); // Store the promise for concurrent calls
        const token = await apigwAuthorizationTokenRequestLock;  // Await the promise to resolve the token
        apigwAuthorizationTokenRequestLock = null; // Reset tokenPromise after the request completes
        return token;
    }

    async function updateAPIGWAuthorizationToken() {
        const isInitialized = await waitForAPIGWAuthorizationTokenInitialization();

        if (!isInitialized) {
            console.error("Failed to initialize access token and account after retries.");
            throw new Error("Access token initialization failed after multiple attempts.");
        }

        try {
            const tokenResponse = await instance.acquireTokenSilent({
                scopes: protectedResources.apiCert.scopes,
                account: account
            });
            window.sessionStorage.setItem('apigw_authorization_token', tokenResponse.idToken);
            return tokenResponse.idToken;
        } catch (error) {
            console.error("Error acquiring token silently:", error);
            try {
                // in case if silent token acquisition fails, fallback to an interactive method
                const tokenResponse = await instance.acquireTokenPopup({
                    scopes: protectedResources.apiCert.scopes,
                    account: account
                });
                window.sessionStorage.setItem('apigw_authorization_token', tokenResponse.idToken);
                return tokenResponse.idToken;
            } catch (popupError) {
                console.error("Error acquiring token via popup:", popupError);
                throw new Error("Failed to acquire token via popup.");
            }
        }
    }

    async function waitForAPIGWAuthorizationTokenInitialization() {
        // Maximum wait time is 1 min (6 retires every 10s)
        const maxAttempts = 6;
        let attempts = 0;

        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                attempts++;

                // Check if the authentication process is done and the account is available
                if (inProgress === "none" && account) {
                    clearInterval(interval);
                    resolve(true);
                }

                // If max attempts reached, stop retrying
                if (attempts >= maxAttempts) {
                    clearInterval(interval);
                    console.error("Authentication process or account initialization failed after multiple attempts.");
                    resolve(false);
                }
            }, 10000);
        });
    }

    async function fetchInvestigationStatus (url, investigation_id) {
        try {
            const apigwAuthorizationToken = await getAPIGWAuthorizationToken()

            const response = await callDynamoDB(apigwAuthorizationToken, url, investigation_id, axios);

            if (!response || !response.Status || response.Status.length === 0) {
                throw new Error("Invalid response format");
            }

            const result = response.Status[0].Progress;
            if (result === undefined) {
                throw new Error("Progress is undefined");
            }

            setStatus(result)
            setMessage(statusMap.get(result));
            window.sessionStorage.setItem('progress', result);

            // If the investigation is in one of the final stages, clear the interval
            if (result === "complete" || result === "noInstanceDetail" || result === "errorProcessingTheInstance") {
                clearInterval(investigationStatusRefreshInterval);

                setIsLoading(false);
                window.sessionStorage.setItem('isLoading', "false");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error("Token expired or unauthorized. Removing the token and forcing a refresh to trigger a new login-in.");
                window.sessionStorage.removeItem('apigw_authorization_token');
                window.location.reload()
            }

            console.error("Error when calling API GW:", error);
            clearInterval(investigationStatusRefreshInterval);  // Clear interval on error
            setMessage("Error when calling API GW:" + error)
        }
    }

    function startInvestigationStatusRefresh(url, investigation_id, intervalDuration = 10000) {
        // Clear any previous intervals before starting a new one
        clearInvestigationStatusRefresh()

        investigationStatusRefreshInterval = setInterval(() => {
            fetchInvestigationStatus(url, investigation_id);
        }, intervalDuration);
    }

    function clearInvestigationStatusRefresh() {
        if (investigationStatusRefreshInterval) {
            clearInterval(investigationStatusRefreshInterval);
        }
    }

        function investigationHandler(e){
            setData({...data, investigation_id: e.target.value});
            window.sessionStorage.setItem('investigation_id', e.target.value);
            
        }

        function instanceHandler(e){
            setData({...data, instance_id: e.target.value});
            window.sessionStorage.setItem('instance_id', e.target.value);
           
        }

        
        function osTypeHandler(e){
            setData({...data, os_type1: e.target.value});
            window.sessionStorage.setItem('os_type1', e.target.value);
        }

        function delStackHandler(e){
            setData({...data, delete_stack: e.target.value});
            window.sessionStorage.setItem('delete_stack', e.target.value);
        }

        async function submitNewInvestigationRequestFormHandler(event){
            event.preventDefault();
            if (data.investigation_id.match(/^([a-z0-9]){10,}$/i)===null){
                document.getElementById("invId").style.display='';
                document.getElementById("instId").style.display='none';           	
            }
            else if (data.instance_id.match(/^i-([a-z0-9]){10,17}$/i)===null){
                document.getElementById("instId").style.display='';
                document.getElementById("invId").style.display='none';
            }

            else{
                document.getElementById("invId").style.display='none';
                document.getElementById("instId").style.display='none';

                window.sessionStorage.setItem('outputFlag', 'true');
                setOutputFlag(true);

                window.sessionStorage.setItem('isLoading', 'true');
                setIsLoading(true);

                window.sessionStorage.setItem('progress', 'initiating');
                setStatus('initiating');

                window.sessionStorage.removeItem('exectId');
                setExecId("");

                setMessage('Submitting request...')

                const apigwAuthorizationToken = await getAPIGWAuthorizationToken()

                let payload ={
                    "instanceid": data.instance_id,
                    "ostype": data.os_type1,
                    "investigationid": data.investigation_id,
                    "teardown": data.delete_stack
                }
                callApiWithToken(apigwAuthorizationToken, protectedResources.apiCert.endpoint, payload)
                    .then(async (response) => {
                        setExecId(response.executionId);
                        window.sessionStorage.setItem('exectId', response.executionId);
                        setMessage('Request submitted, waiting for updates...')

                        investigationStatusRefreshInterval = setInterval(fetchInvestigationStatus, 30000, protectedResources.apiCertDdb.endpoint, data.investigation_id );
                    })
                    .catch( error => {
                        if (error.response && error.response.status === 401) {
                            console.error("Token expired or unauthorized. Removing the token and forcing a refresh to trigger a new login-in.");
                            window.sessionStorage.removeItem('apigw_authorization_token');
                            alert("Session token has expired or is unauthorized. Please re-submit your request")
                            window.location.reload()
                        } else {
                            setMessage('Request failed: ' + error)
                            console.error("An unexpected error occurred:", error);
                        }
                    });
            }
        }

        return (
            <div>
                <form onSubmit= {(e) => submitNewInvestigationRequestFormHandler(e) }>
                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-1">Investigation Id</label> 
                        </div>
                        <div className="col-75">
                            <input style = { {height: "30px"} } type="text" id="txtInvestigationId" value = {data.investigation_id} onChange={investigationHandler}/>
                            <span id="invId"  style={{color:'red', display: 'none'}}> Investigation Id - minimum 10 characters [a-z0-9] </span>
                        </div>
                             
                    </div> 

                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-2">Instance Id</label> 
                        </div>
                        <div className="col-75">
                            <input style = { {height: "30px"} } type="text" id="txtInstanceId" value = {data.instance_id} onChange={instanceHandler}/>
                            <span id="instId" style={{color:'red', display: 'none'}}> Instance id - begin with "i-" follow by 10 to 17 characters </span>
                        </div>
                               
                    </div> 
                
                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-3">Os-Type</label> 
                        </div>
                        <div className="col-75">
                            <select  style = { {height: "30px"} } value = {data.os_type1} onChange={osTypeHandler}>
                                <option value="Windows">Windows</option>
                                <option value="Linux">Linux</option>
                            </select>
                        </div>
                    </div>

                    <div className ="row">
                        <div className="col-25">
                            <label htmlFor="input-4">Delete-Stack</label> 
                        </div>
                        <div className="col-75"  onChange = { delStackHandler }>
                            <input  type="radio" name="deleteStack" value="enable"  checked = { data.delete_stack ==="enable"}/> Enable &nbsp;&nbsp;&nbsp;  <input type="radio" name="deleteStack" value="empty"  checked = { data.delete_stack ==="empty"}/> None
                        </div>
                    </div>

                    <div className ="row">
                        <input type="submit" id= "submitButton" value="Submit"/> 
                    </div>                
                </form>
                <div>
                    {outputFlag && 
                         <span>
                            <div style= {{ overflowX: 'auto' }}>
                                {isLoading && <h4>
                                    <img src={Spinner} width="50" height="50" alt=""/>
                                    Request is being processed and current status is <b>{status}</b>
                                </h4>}
                              
								<table>
									<thead>
										<tr>
											<th>Execution Id</th>
											<th>Progress</th>
											<th>Message</th>
										</tr>
									</thead>
									<tbody>
										<tr>		
											<td>{exectId}</td>
											<td>{isLoading && <img src={Spinner} width="27" height="27" alt=""/>}{status}</td>
											<td>{message}</td>
										</tr>
									</tbody>
								</table>
                            </div>
                        </span>}
                </div>
            </div>
        );
    }