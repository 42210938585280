import React from 'react';
import Logo from '../../images/aws-security-logo.png'
import '../component-css/Header.css';
import { loginRequest } from "../common/authConfig";
import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { Button, Dropdown } from "react-bootstrap";

const StyledDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button 
        style={{float: "right", height: '40px', marginRight: "10px"}} 
        className="header-btn" 
        ref={ref}
        variant=""
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >{children}</Button>
  ));

const Header = () => {
    const { instance, accounts, /*inProgress*/ } = useMsal();
    const account = useAccount(accounts[0] || {});

    return(
        <div className ="t1">
            <div className="child-left">
                <a href="/">
                    <img src={Logo} width="240" className="logo d-inline-block align-top" alt=""/>
                </a>
            </div>
            <div className="child-right">
                <AuthenticatedTemplate>
                    <Dropdown>
                        <Dropdown.Toggle as={StyledDropdownToggle}>
                            {account.name || "User"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.ItemText>{account.username}</Dropdown.ItemText>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" }) }>
                                Sign Out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </AuthenticatedTemplate>
                
                <UnauthenticatedTemplate>
                    <Button style={{float: "right", height: '40px'}} className="header-btn" onClick={() =>{
                        instance.loginPopup(loginRequest);
                    }}>Sign In</Button>
                </UnauthenticatedTemplate> 
            </div>
        </div>    
    );
};
export default Header;