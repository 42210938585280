import React, { useMemo } from 'react';
import  { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import "../component-css/SideBar.css"

const HomeLink = styled(Link)`
    font-size: 18px;
    font-weight:bold;
    
    &:hover {
        color: #FED41D;
    }
`;
const HomeLabel = styled.span`
    
`;    

const HomeMenu = ({item, rootPath}) => {
    
    const location = useLocation();

    const isSelected = useMemo(() => {
        return location.pathname === `${rootPath}/${item.path}`;
    }, [rootPath, item.path, location]);

    const url = `${rootPath}/${item.path}`

    return (
        <div>
            <HomeLink to = {url}>
                <div className={isSelected ? "active" : ""}>
                    <HomeLabel>
                        &rsaquo;
                        &nbsp;
                        {item.title}
                    </HomeLabel>
                </div>
            </HomeLink>     
        </div>
    );
}

export default HomeMenu;