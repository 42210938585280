import { createContext, useEffect, useState, useRef } from "react";

export const SessionStoreContext = createContext();

const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef()
  
  cb.current = handler
  
  useEffect(() => {
    const handler = () => cb.current()
  
    window.addEventListener('beforeunload', handler)
    
    return () => {
      if(callOnCleanup) handler()
    
      window.removeEventListener('beforeunload', handler)
    }
  }, [cb, callOnCleanup])
}

export const SessionStoreProvider = ({ storekey, children }) => {

    const [readyState, setReadyState] = useState(false);
    const [sessionStore, setSessionStore] = useState({});

    useWindowUnloadEffect(
        () => sessionStorage.setItem(storekey, JSON.stringify(sessionStore))
    , true);

    const fetch = () => {
        const preferences = sessionStorage.getItem(storekey);

        if (preferences !== null)
            setSessionStore(JSON.parse(preferences));

        setReadyState(true);
    }

    useEffect(fetch, []);

    return (
        <SessionStoreContext.Provider
            value={{
                getItem: (key, fallback) => {
                    console.info(sessionStore);
                    if (sessionStore[key] === undefined) {
                        console.info(`${storekey} - getItem: ${key} => ${fallback} (fallback)`);
                        return fallback;
                    } else {
                        console.info(`${storekey} - getItem: ${key} => ${sessionStore[key]}`);
                        return sessionStore[key];
                    }
                },

                setItem: (key, value) => {
                    console.info(`${storekey} - setItem: ${key} <= ${value}`);
                    sessionStore[key] = value;
                },

                clear: () => {
                    setSessionStore({});
                    console.info(`${storekey} - clear`);
                },

                useOnReady: (callback) => useEffect(callback, [callback, readyState]), 
            }}
        >
            {children}
        </SessionStoreContext.Provider>
    );
}