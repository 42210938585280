import React from 'react';
import { NavLink } from "react-router-dom";
import { useMsal, useAccount, AuthenticatedTemplate } from "@azure/msal-react";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import '../component-css/NaviBar.css';


const NaviBar = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const getClass = (navData) => [
        "link",
        (navData.isActive ? "active" : ""),
    ].join(" ");

    return (

        <Navbar className="ul">
            <AuthenticatedTemplate>
                
                <Nav className="li">
                    <NavItem className='li'>
                        <NavLink exact="true" to="/" className={navData => getClass(navData)}>Home</NavLink>
                    </NavItem>

                    {/*<NavItem className='li'>
                        <NavLink to="/demo" exact="true" className={navData => getClass(navData)}>CPIN</NavLink>
                    </NavItem>*/}
                    <NavItem className='li'>
                        <NavLink to="/cert" exact="true" className={navData => getClass(navData)}>CERT</NavLink>
                    </NavItem>
                    <NavItem className='li'>
                        <NavLink to="/Reporting" className={navData => getClass(navData)}>Reporting</NavLink>
                    </NavItem>
                    {/*<NavItem className='li'>
                        <NavLink to="/wiki" exact="true" className={navData => getClass(navData)}>Wiki</NavLink>
                    </NavItem>*/}
                    {/*<NavItem className='li'>
                        <NavLink to="/memo" exact="true" className={navData => getClass(navData)}>Feedback</NavLink>
                    </NavItem>*/}
                </Nav>
               
            </AuthenticatedTemplate>
        </Navbar>


    );
}
export default NaviBar;



