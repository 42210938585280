import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { callApiWithToken } from "../components/common/fetch";

const getIdToken = async (account, msalInstance, scopes) => {
    let idToken;
    try {
        const response = await msalInstance.acquireTokenSilent({
            scopes,
            account
        });

        idToken = response.idToken
    } catch(error) {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
            const response = await msalInstance.acquireTokenPopup({
                scopes,
            });

            idToken = response.idToken
        } else console.error(error);
    };

    return idToken;
}

class PaginatedQuery {
    constructor(queryString, queryName, endpoint, scopes) {
        this.queryString = queryString;
        this.queryName = queryName;
        this.endpoint = endpoint;
        this.scopes = scopes;
        this.nextToken = null;
    }

    async getFirstPage(account, instance, variables) {
        console.log(`Retrieving first page for ${this.queryName}...`);

        const body = JSON.stringify({
            query: this.queryString,
            variables: {
                ...variables,
                nextToken: null
            },
        });

        const apiResponse = await callApiWithToken(
            await getIdToken(account, instance, this.scopes),
            this.endpoint,
            body
        );

        console.log(apiResponse);
        this.nextToken = apiResponse.data[this.queryName].nextToken ?? null;

        return apiResponse.data[this.queryName].items;
    }

    async getPage(account, instance, variables) {
        console.log(`Retrieving page for ${this.queryName} (nextToken=${this.nextToken && `${this.nextToken.slice(0, 50)}...`})...`);

        const body = JSON.stringify({
            query: this.queryString,
            variables: {
                ...variables,
                nextToken: this.nextToken
            },
        });

        const apiResponse = await callApiWithToken(
            await getIdToken(account, instance, this.scopes),
            this.endpoint,
            body
        );

        console.log(apiResponse);
        this.nextToken = apiResponse.data[this.queryName].nextToken ?? null;

        return apiResponse.data[this.queryName].items;
    }

    async getFullResult(account, instance, variables) {
        let items = [];
        do {
            const page = await this.getPage(account, instance, variables);
            const filteredPage = page.filter(item => item !== null);
                items = items.concat(filteredPage);
        } while (this.nextToken !== null);

        return items;
    }

    hasMoreResults() {
        return this.nextToken !== null;
    }
}

export default PaginatedQuery;