import React, {useRef, useState} from "react";

const DelegateMembersCellRenderer = ({value}) => {
  const [isOpen, setIsOpen] = useState(false);
  const content = useRef(null);
  const length = value.length;

  return (
    <>
      {isOpen || length <= 2 ? (
        <ul>
          {value.map((delegate, i) => (
            <li key={i}>{delegate.name}</li>
          ))}
          {length > 2 && <li key="hide" onClick={() => setIsOpen(false)} style={{ cursor: 'pointer', color: '#337ab7' }}>
            <span>[Hide]</span>
          </li>}
        </ul>
      ) : (
        <ul>
          {length > 0 && <li key="first"> {value[0].name}</li>}
          {length > 1 && <li key="second"> {value[1].name}</li>}
          {length > 2 &&
            <li key="more" onClick={() => setIsOpen(true)} style={{ cursor: 'pointer', color: '#337ab7' }}>
              <span>[Show {length - 2} more]</span>
            </li>}
        </ul>
      )}
    </>
  )
};

const useDelegateMembersRenderer = () => {
  return [DelegateMembersCellRenderer];
};

export default useDelegateMembersRenderer;