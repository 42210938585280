import React from "react";
import Table from "../common/Tables";
import useJsonCellRenderer from "../common/JsonCellRenderer";

export const WafResourceData = (props) => {
    const [ModalDiv, JsonCellRenderer] = useJsonCellRenderer();

    const columns = React.useMemo(
        () => [
                {
                    Header: 'ResourceId',
                    accessor: 'resourceid',
                    sortType: 'basic',
                },
                {
                    Header: 'ResourceType',
                    accessor: 'resourcetype',
                    sortType: 'basic',
                },
                {
                    Header: 'Account Id',
                    accessor: 'accountid',
                    sortType: 'basic',
                },
                {
                    Header: 'Region',
                    accessor: 'region',
                    sortType: 'basic',
                },
                {
                    Header: 'CloudFront Origins',
                    accessor: 'cf_origins',
                    Cell: JsonCellRenderer,
                },
                {
                    Header: 'CloudFront Resource Id',
                    accessor: 'cloudfront_id',
                    sortType: 'basic',
                },
                {
                    Header: 'CloudFront WebACL Id',
                    accessor: 'cloudfront_webacl',
                    sortType: 'basic',
                },
                {
                    Header: 'WebACL Id',
                    accessor: 'webacl_resourceid',
                    sortType: 'basic',
                },
                {
                    Header: 'WebACL Type',
                    accessor: 'webacl_resourcetype',
                    sortType: 'basic',
                },
                {
                    Header: 'WebACL Is Managed',
                    accessor: 'webacl_is_managed',
                    sortType: 'basic',
                },
                {
                    Header: 'WebACL Excluded Rules',
                    accessor: 'webacl_managed_rule_exceptions',
                    Cell: JsonCellRenderer,
                },
                {
                    Header: 'WebACL Custom Rules',
                    accessor: 'webacl_custom_rules',
                    Cell: JsonCellRenderer,
                },
            ], [JsonCellRenderer]
        );

        const csvheaders = React.useMemo(
            () => [
                {
                    label: 'ResourceId',
                    key: 'resourceid',
                },
                {
                    label: 'ResourceType',
                    key: 'resourcetype',
                },
                {
                    label: 'Account Id',
                    key: 'accountid',
                },
                {
                    label: 'Region',
                    key: 'region',
                },
                {
                    label: 'CloudFront Origins',
                    key: 'cf_origins',
                },
                {
                    label: 'CloudFront Resource Id',
                    key: 'cloudfront_id',
                },
                {
                    label: 'CloudFront WebACL Id',
                    key: 'cloudfront_webacl',
                },
                {
                    label: 'WebACL Id',
                    key: 'webacl_resourceid',
                },
                {
                    label: 'WebACL Type',
                    key: 'webacl_resourcetype',
                },
                {
                    label: 'WebACL Is Managed',
                    key: 'webacl_is_managed',
                },
                {
                    label: 'WebACL Excluded Rules',
                    key: 'webacl_managed_rule_exceptions',
                },
                {
                    label: 'WebACL Custom Rules',
                    key: 'webacl_custom_rules',
                },
            ],
            []
        );

        console.log(props);
    return (
        <>
            <ModalDiv />
            <Table columns={columns} data={props.wafResourceData ?? []} csvheaders={csvheaders} />
        </>
    );
}