import { useEffect } from 'react'
import axios from 'axios'
import { useMsal, useAccount } from "@azure/msal-react";
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import SideBarDatajson from "../../utils/data/CertSideBarData.json";

import SideBar from '../../components/component-pages/SideBar';
import { protectedResources } from "../../components/common/authConfig";
import { callDynamoDB } from "../../certapi/fetchCertDdb";

import "./Cert.css";

const Cert = () => {
  const [validation, setValidation] = useState(false)
  const [loading, setLoading] = useState(true);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    async function checkValidation() {
      try {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        instance.acquireTokenSilent({
          scopes: protectedResources.apiCert.scopes,
          account: account
        }).then(async(response) => {
            let dbToken=response.idToken;
            let url=protectedResources.apiCertDdb.endpoint;
            let id='Authorisation';
            let dbResponse = await callDynamoDB(dbToken, url, id, axios);
          
            if  (dbResponse!== undefined && dbResponse.Status !== undefined) {
              setValidation(true)
            } else {
              setValidation(false)
            }
      }).catch((error) => {
        setValidation(false)
      });
    } catch (error) {
      console.error(error);
    }
  }
  checkValidation();
  },[]);

  return (
    <div className="row-portal">
        <div className="column1-portal">
            <SideBar data={SideBarDatajson} rootPath="/cert"/>
        </div>
        <div className="column2-portal">
          {
            loading ? <div className="loading">Loading...</div> :
              (!validation ? <div className="not-authorized">Sorry, Not authorised to access this page...</div> : <Outlet />)
          }
        </div>
    </div>
  );
};
export default Cert


