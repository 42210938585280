import React from "react";
import Table from "../common/Tables";
import useDelegateMembersRenderer from "../common/DelegateMembers";
import MultipleElementFilter, {includesSome} from "../common/MultipleElementFilter";

export const CloudEnvironmentData = (props) => {
    const [DelegateMembersCellRenderer] = useDelegateMembersRenderer();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Cloud Environment ID',
                accessor: 'cloud_environment_id',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'Friendly Name',
                accessor: 'friendly_name',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'Status',
                accessor: 'status',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'Environment Type',
                accessor: 'environment_type',
                filter: includesSome,
                Filter: MultipleElementFilter,
                sortType: 'basic',
            },
            {
                Header: 'IT Service Owner Name',
                accessor: 'it_service_owner_name',
                filter: 'fuzzyText',
                sortType: 'basic',
            },
            {
                Header: 'IT Service Owner Email',
                accessor: 'it_service_owner_email',
                sortType: 'basic',
            },
            {
                Header: 'Delegates Group Name',
                accessor: 'delegates_group_name',
                sortType: 'basic',
            },
            {
                Header: 'Delegates Members',
                accessor: 'delegates_members',
                Cell: DelegateMembersCellRenderer,
            },
        ],
        [DelegateMembersCellRenderer]
    );

    const csvheaders = React.useMemo(
        () => [
            {
                label: 'Cloud Environment ID',
                key: 'cloud_environment_id',
            },
            {
                label: 'Friendly Name',
                key: 'friendly_name',
            },
            {
                label: 'Status',
                key: 'status',
            },
            {
                label: 'Environment Type',
                key: 'environment_type',
            },
            {
                label: 'IT Service Owner Name',
                key: 'it_service_owner_name',
            },
            {
                label: 'IT Service Owner Email',
                key: 'it_service_owner_email',
            },
            {
                label: 'Delegates Group Name',
                key: 'delegates_group_name',
            },
            {
                label: 'Delegates Members',
                key: 'delegates_members',
            },
        ],
        []
    );

    return (
        <>
            <Table
                columns={columns}
                data={props.cloudEnvironmentData ?? []}
                csvheaders={csvheaders}
                contactsGroupConfiguration={{
                    enable: true,
                    fieldsNames: {
                        serviceOwnerEmail: "it_service_owner_email",
                        serviceOwnerName: "it_service_owner_name",
                        delegates: "delegates_members",
                        delegatesEmail: "email",
                        delegatesName: "name",
                    }
                }}
            />
        </>
    );
}