import {useEffect, useState} from "react";
import {MsalAuthenticationTemplate, useAccount, useMsal} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {loginRequest, protectedResources} from "../../components/common/authConfig";
import {AwsAccountData} from "../../components/Reporting/AwsAccountItem";
import PaginatedQuery from "../../utils/PaginatedQuery";

function AwsAccountsPage() {
    return (
        <div>
            <AwsAccountItems />
        </div>
    );
}

export default AwsAccountsPage;

export const AwsAccountItems = (props) => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest} >
            <AWSAccountItem props={props} />
        </MsalAuthenticationTemplate>
    );
};

const AWSAccountItem = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [results, setResults] = useState(null);

    const queryName = "listAwsAccounts";
    const queryString = `
        query ${queryName}($nextToken: String) {
            ${queryName}(nextToken: $nextToken) {
                items {
                    account_id
                    account_name
                    status
                    account_type
                    environment_type
                    short_description
                    aws_joined_method
                    aws_joined_date
                    aws_platform_status
                    aws_organisation_master_account_id
                    aws_organisation_type
                    aws_organisation_name
                    it_service_name
                    it_service_portfolio_name
                    it_service_owner_name
                    it_service_owner_ntid
                    it_service_owner_email
                    delegates_group_name
                    security_tooling_deploy
                    global_rbac_deployed
                    pci
                    delegates_members {
                        email
                        name
                    }
                    internet_facing
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiReporting.endpoint,
        protectedResources.apiReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none") {
                query.getFullResult(
                    account,
                    instance,
                    {}
                ).then(data => setResults(data))
            }
        },
        [account, inProgress, instance]
    );

    console.log(results)
    return (
        <>
            {results ? <AwsAccountData awsAccountData={results} /> : null}
        </>
    );
};