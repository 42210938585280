import { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { CFormLabel, CForm } from '@coreui/react';
import { protectedResources } from "./authConfig";
import { SearchListData } from "./SearchListData"
import "../../App.css";
import PaginatedQuery from "../../utils/PaginatedQuery";


const SearchListContent = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [searchData, setSearchData] = useState(null);  

    const queryName = "listLookups";
    const queryString = `
        query ${queryName}($type: String!, $nextToken: String) {
            ${queryName}(type: $type, nextToken: $nextToken) {
                items {
                    name 
                }
                nextToken
            }
        }
    `;

    const query = new PaginatedQuery(
        queryString,
        queryName,
        protectedResources.apiReporting.endpoint,
        protectedResources.apiReporting.scopes,
    );

    useEffect(
        () => {
            if (account && inProgress === "none")
                query.getFullResult(
                    account,
                    instance,
                    {type: props.type}
                ).then(data => setSearchData(data))
        },
        [account, inProgress, instance]
    );
    return (
        <>
            { searchData ? 
                <SearchListData searchData={searchData} ctx={props.ctx} /> : null }
        </>
    );
};

/**
 * @param props.onFilter           - callback for when filter value changes 
 * @param props.searchCriteriaName - used in the form label and form button text
 * @param props.type               - value of type variable in lookup query (eg. publicip, waf_resource, service, ...)
 */
const SearchListForm = (props) => {
    const [context, setContext] = useState("");
    const submitHandler = (event) => {
        event.preventDefault();
        console.log(context);
        props.onFilter(context);
    }
    
    return (

        <CForm border="primary" style={{ width: '30rem' }} onSubmit={submitHandler}>
            <CFormLabel htmlFor="floatingTextarea">Search Criteria - {props.searchCriteriaName}</CFormLabel>
            <div className="clearfix"></div>
            <div className="searchBox">
                <SearchListContent ctx={[context, setContext]} type={props.type} />
            </div>
            <button type="submit" className="formButton searchButton"></button>
            <div className="clearfix"></div>
        </CForm>
    )
};

export default SearchListForm;